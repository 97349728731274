import {useState, useEffect, useContext, useCallback} from 'react';
import ENDPOINT from '../../../info/endpoints';
import info from '../../../info/ru/cabinet.json';
import Input, {INPUT_TYPE} from '../../components/form/input';
import {CabinetPopup} from '../../components/popup';
import API from '../../functions/api';
import AuthContext, {AuthState} from '../../functions/auth';
import sex from '../../../info/ru/sex.json';
import regions from '../../../info/ru/regions.json';
import tshirt_size from '../../../info/ru/tshirt_size.json';

const POPUP_STATE = {
  DATA_CALL: 'data',
  PASSWORD_UPDATE: 'password'
};

function Settings({data}) {
  const {authState} = useContext(AuthContext);

  const isOrganizer = authState === AuthState.ORGANIZER;

  const [popupState, setPopupState] = useState(null);
  const [dataButtonIsDisabled, setDataButtonIsDisabled] = useState(true);
  const [passwordButtonIsDisabled, setPasswordButtonIsDisabled] = useState(true);
  const [dataInputData, setDataInputData] = useState(data);
  const [passwordInputData, setPasswordInputData] = useState(data);
  const [errorData, setErrorData] = useState({});

  const handleDataSubmit = async (e) => {
    e.preventDefault();

    setDataButtonIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    if (query?.logo?.size === 0) {
      delete query?.logo;
    }

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    await API.PATCH(isOrganizer ? ENDPOINT.organizer_me : ENDPOINT.participant_me, query, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setErrorData({});
          setPopupState(POPUP_STATE.DATA_CALL);
        }
      })
      .catch((error) => {
        setErrorData(error?.response?.data);
      });

    setDataButtonIsDisabled(false);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    setPasswordButtonIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    await API.PATCH(ENDPOINT.password_update, query)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setErrorData({});
          setPopupState(POPUP_STATE.PASSWORD_UPDATE);
        }
      })
      .catch((error) => {
        setErrorData(error?.response?.data);
      });

    setPasswordButtonIsDisabled(false);
  };

  const enableDataButtonIfDisabled = useCallback(
    () => setDataButtonIsDisabled((isDisabled) => (isDisabled ? false : isDisabled)),
    [setDataButtonIsDisabled]
  );

  const enablePasswordButtonIfDisabled = useCallback(
    () => setPasswordButtonIsDisabled((isDisabled) => (isDisabled ? false : isDisabled)),
    [setPasswordButtonIsDisabled]
  );

  const handleDataInputChange = (e) => {
    setDataInputData({
      ...dataInputData,
      [e.target.name]: e.target.value
    });

    enableDataButtonIfDisabled();
  };

  const handlePasswordInputChange = (e) => {
    setPasswordInputData({
      ...passwordInputData,
      [e.target.name]: e.target.value
    });

    enablePasswordButtonIfDisabled();
  };

  useEffect(() => {
    setDataInputData(data);
  }, [data]);

  return (
    <>
      {popupState === POPUP_STATE.DATA_CALL && (
        <CabinetPopup setter={setPopupState} info={info.settings.popup} />
      )}
      {popupState === POPUP_STATE.PASSWORD_UPDATE && (
        <CabinetPopup setter={setPopupState} info={info.settings.password_popup} />
      )}

      <div className='cabinet__heading-wrapper'>
        {data?.logo ? <img className='cabinet__heading-logo' src={data.logo} alt='logo' /> : null}
        <h2 className='cabinet__heading'>{info.settings.heading}</h2>
      </div>

      <div className='cabinet__settings advert'>
        <form className='login__form jcsb-aifs' onSubmit={handleDataSubmit}>
          <div className='login__form-left'>
            <Input
              value={dataInputData?.phone || ''}
              onChange={handleDataInputChange}
              text={info.settings.form.phone + '*'}
              type='tel'
              error={errorData?.phone}
              name='phone'
              required
            />

            {isOrganizer && (
              <>
                <Input
                  value={dataInputData?.name || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.name + '*'}
                  type='text'
                  error={errorData?.name}
                  name='name'
                  required
                />

                <Input
                  value={dataInputData?.url || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.url + '*'}
                  type='text'
                  error={errorData?.url}
                  name='url'
                  required
                />

                <Input
                  text={info.settings.form.logo}
                  onChange={enableDataButtonIfDisabled}
                  type='file'
                  error={errorData?.logo}
                  name='logo'
                />
              </>
            )}

            {!isOrganizer && (
              <>
                <Input
                  value={dataInputData?.first_name || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.first_name}
                  type='text'
                  error={errorData?.first_name}
                  name='first_name'
                  required
                />

                <Input
                  value={dataInputData?.middle_name || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.middle_name + '*'}
                  type='text'
                  name='middle_name'
                  required
                  error={errorData?.middle_name}
                />

                <Input
                  value={dataInputData?.sex || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.sex + '*'}
                  inputType={INPUT_TYPE.SELECT}
                  name='sex'
                  required
                  error={errorData?.sex}
                >
                  {sex.map((value, index) => (
                    <option value={value.value} key={index}>
                      {value.title}
                    </option>
                  ))}
                </Input>

                <Input
                  value={dataInputData?.residence_region || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.residence_region + '*'}
                  inputType={INPUT_TYPE.SELECT}
                  name='residence_region'
                  required
                  error={errorData?.residence_region}
                >
                  {regions.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </Input>
              </>
            )}

            <button type='submit' className='inactive' disabled={dataButtonIsDisabled}>
              {info.settings.form.button}
            </button>
          </div>
          <div className='login__form-right'>
            <Input
              value={dataInputData?.email || ''}
              onChange={handleDataInputChange}
              text={info.settings.form.email + '*'}
              type='email'
              error={errorData?.email}
              name='email'
              required
            />

            {isOrganizer && (
              <>
                <Input
                  value={dataInputData?.contact_name || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.contact_name + '*'}
                  type='text'
                  error={errorData?.contact_name}
                  name='contact_name'
                  required
                />

                <Input
                  value={dataInputData?.contact_name_job || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.contact_name_job + '*'}
                  type='text'
                  error={errorData?.contact_name_job}
                  name='contact_name_job'
                  required
                />
              </>
            )}

            {!isOrganizer && (
              <>
                <Input
                  value={dataInputData?.last_name || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.last_name + '*'}
                  type='text'
                  name='last_name'
                  required
                  error={errorData?.last_name}
                />

                <Input
                  value={dataInputData?.birth_day || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.birth_day + '*'}
                  type='date'
                  name='birth_day'
                  required
                  error={errorData?.birth_day}
                />

                <Input
                  value={dataInputData?.tshirt_size || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.tshirt_size + '*'}
                  inputType={INPUT_TYPE.SELECT}
                  name='tshirt_size'
                  required
                  error={errorData?.tshirt_size}
                >
                  {tshirt_size.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </Input>

                <Input
                  value={dataInputData?.living_region || ''}
                  onChange={handleDataInputChange}
                  text={info.settings.form.living_region + '*'}
                  inputType={INPUT_TYPE.SELECT}
                  name='living_region'
                  required
                  error={errorData?.living_region}
                >
                  {regions.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </Input>
              </>
            )}
          </div>
        </form>
      </div>

      <form className='cabinet__password advert' onSubmit={handlePasswordSubmit}>
        <div className='login__form jcsb-aifs'>
          <div className='login__form-left jcfs-aic wrap'>
            <Input
              text={info.settings.password.old + '*'}
              onChange={handlePasswordInputChange}
              type='password'
              error={errorData?.old_password}
              name='old_password'
              required
            />

            <Input
              text={info.settings.password.new_repeat + '*'}
              onChange={handlePasswordInputChange}
              type='password'
              error={errorData?.new_password2}
              name='new_password2'
              required
            />

            <button type='submit' className='inactive' disabled={passwordButtonIsDisabled}>
              {info.settings.password.change}
            </button>
          </div>
          <div className='login__form-right jcc-aifs wrap'>
            <Input
              text={info.settings.password.new + '*'}
              onChange={handlePasswordInputChange}
              type='password'
              error={errorData?.new_password}
              name='new_password'
              required
            />

            <h6>{info.settings.password.requirements}</h6>
          </div>
        </div>
      </form>
    </>
  );
}

export default Settings;
