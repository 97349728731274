function Banner({background, title}) {
  return (
    <div className='banner w-full jcc-aic relative'>
      <img src={background} alt={title} />
      <div className='banner__holder absolute jcc-aife wh-full'>
        <div className='banner__container'>
          <h5>
            Главная {'>'} {title}
          </h5>
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
