import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const parseHtml = (htmlString) => {
  const cleanHtmlString = DOMPurify.sanitize(htmlString, {USE_PROFILES: {html: true}});
  const html = parse(cleanHtmlString);

  if (html === htmlString) {
    return false;
  }

  return html;
};

export default parseHtml;
