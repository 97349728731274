import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT, {MANAGER_ORIGIN} from '../../info/endpoints';
import {externalAPI} from './store';
import {useParams} from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import {Subscription} from './news';
import DOMPurify from 'dompurify';

function NewsArticle() {
  const {id: articleID} = useParams();
  const [articleData, setArticleData] = useState({});

  useEffect(() => {
    if (articleID) {
      API.GET(ENDPOINT.manager.news_article + `/${articleID}`, externalAPI).then((response) => {
        setArticleData(response?.data);
      });
    }
  }, [articleID]);

  return (
    <div className='news__article news__container jcc-aic wrap'>
      <Header />

      <div
        className='banner w-full jcc-aic relative'
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8))${
            articleData?.banners ? `, url(${MANAGER_ORIGIN + articleData?.banners?.main})` : ''
          }`
        }}
      >
        <div className='banner__holder absolute jcc-aife wh-full'>
          <div className='banner__container'>
            <h6>
              {new Date(articleData?.publication_date * 1000).toLocaleString('ru', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </h6>

            <h3>{articleData?.headline}</h3>

            {articleData?.subtitle ? <h5>{articleData?.subtitle}</h5> : null}
          </div>
        </div>
      </div>

      <Main article={articleData} />

      <Footer />
    </div>
  );
}

const Main = ({article}) => (
  <div className='news__main'>
    <div
      className='news__article-text'
      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(article?.text)}}
    ></div>

    <Subscription />
  </div>
);

export default NewsArticle;
