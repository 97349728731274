import {Routes, Route, useLocation, Navigate} from 'react-router-dom';

import titles from './info/titles.json';

import Home from './js/pages/home';
import Federation from './js/pages/federation';
import Protocols from './js/pages/protocols';
import Doping from './js/pages/doping';
import AccreditedRaces from './js/pages/accreditedRaces';
import Contacts from './js/pages/contacts';
import Partners from './js/pages/partners';
import Offices from './js/pages/offices';
import Contribution from './js/pages/contribution';
import QualifyingRaces from './js/pages/qualifyingRaces';
import Calendar from './js/pages/calendar';
import Store from './js/pages/store';
import Product from './js/pages/product';
import Cart from './js/pages/cart';
import Delivery from './js/pages/article/delivery';
import Payment from './js/pages/article/payment';
import Return from './js/pages/article/return';
import Signup, {SignupState} from './js/pages/cabinet/signup';
import Signin from './js/pages/cabinet/signin';
import Cabinet, {SECTION} from './js/pages/cabinet/cabinet';
import {useEffect} from 'react';

import device from './js/functions/device';
import {AuthProvider, AuthState} from './js/functions/auth';
import {EventProvider} from './js/pages/cabinet/eventContext';
import {CartProvider} from './js/pages/cartContext';
import PasswordReset from './js/pages/cabinet/passwordReset';
import Contact from './js/pages/article/contacts';
import Team from './js/pages/team';
import News from './js/pages/news';
import NewsArticle from './js/pages/newsArticle';

function App() {
  function usePageChange() {
    let location = useLocation();

    useEffect(() => {
      if (device('mobile')) {
        document.querySelector('body').style.overflow = 'auto'; // change overflow after every url change (to avoid hamburger stuck)
      }
    }, [location]);
  }

  usePageChange();

  return (
    <CartProvider>
      <AuthProvider>
        <EventProvider>
          <CreateSwitch />
        </EventProvider>
      </AuthProvider>
    </CartProvider>
  );
}

function CreateSwitch() {
  const links = titles.titles; // header & footer list
  const others = titles.others; // the rest of

  return (
    <Routes>
      <Route path='/' element={<Home />} />

      <Route path={links.federation.link} element={<Federation />} />

      <Route path={links.team.link} element={<Team />} />

      <Route path={links.protocols.link} element={<Protocols />} />

      <Route path={links.doping.link} element={<Doping />} />

      <Route path={links.accreditedRaces.link} element={<AccreditedRaces />} />

      <Route path={links.contacts.link} element={<Contacts />} />

      <Route path={links.partners.link} element={<Partners />} />

      <Route path={links.offices.link} element={<Offices />} />

      <Route path={others.contribution.link} element={<Contribution />} />

      <Route path={links.qualifyingRaces.link} element={<QualifyingRaces />} />

      <Route path={links.calendar.link} element={<Calendar />} />

      <Route path={links.calendar.link + '/:year'} element={<Calendar />} />

      <Route path={links.store.link} element={<Store />} />

      <Route path={links.news.link} element={<News />} />

      <Route path={links.news.link + '/:id'} element={<NewsArticle />} />

      <Route path={others.product.link + '/:id'} element={<Product />} />

      <Route path={others.cart.link} element={<Cart />} />

      <Route path={others.delivery.link} element={<Delivery />} />
      <Route path={others.storeContacts.link} element={<Contact />} />

      <Route path={others.payment.link} element={<Payment />} />

      <Route path={others.return.link} element={<Return />} />

      <Route path={others.signin.link} element={<Signin />} />

      <Route
        path={others.signin.state.organizer.link}
        element={<Signin forwardedAuthState={AuthState.ORGANIZER} />}
      />

      <Route
        path={others.signin.state.sportsman.link}
        element={<Signin forwardedAuthState={AuthState.SPORTSMAN} />}
      />

      <Route path={others.signup.link} element={<Signup />} />

      <Route path={others.password_reset.link + '/:token'} element={<PasswordReset />} />

      <Route
        path={others.signup.state.organizer.link}
        element={<Signup forwardedSignupState={SignupState.ORGANIZER} />}
      />

      <Route
        path={others.signup.state.sportsman.link}
        element={<Signup forwardedSignupState={SignupState.SPORTSMAN} />}
      />

      <Route
        path={others.cabinet.link}
        element={<Navigate to={others.cabinet.section.settings.link} replace />}
      />

      <Route
        path={others.cabinet.section.settings.link}
        element={<Cabinet forwardSection={SECTION.SETTINGS} />}
      />

      {/* <Route
        path={others.cabinet.section.licenses.link}
        element={<Cabinet forwardSection={SECTION.LICENSES} />}
      /> */}

      <Route
        path={others.cabinet.section.licenses.link}
        element={<Cabinet forwardSection={SECTION.LICENSES} />}
      />

      <Route
        path={others.cabinet.section.qual.link}
        element={<Cabinet forwardSection={SECTION.QUAL} />}
      />

      <Route
        path={others.cabinet.section.event_create.link}
        element={<Cabinet forwardSection={SECTION.EVENT_CREATE} />}
      />

      <Route
        path={others.cabinet.section.event_change.link}
        element={<Cabinet forwardSection={SECTION.EVENT_CHANGE} />}
      />

      <Route
        path={others.cabinet.section.event_list.link}
        element={<Cabinet forwardSection={SECTION.EVENT_LIST} />}
      />
    </Routes>
  );
}

export default App;
