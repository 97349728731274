import {StoreLayout} from '../components/layout';
import Pagination from '../components/pagination';
import info from '../../info/ru/store.json';
import productInfo from '../../info/ru/product.json';
import titles from '../../info/titles.json';

import {Link} from 'react-router-dom';
import {useContext, useEffect, useMemo, useState} from 'react';
import API from '../functions/api';
import ENDPOINT, {LEAGUE_ORIGIN} from '../../info/endpoints';
import CartContext from './cartContext';
import {MultiRange} from '../components/form/multiRange';
import {ProductAddPopup, PRODUCT_ITEM_POPUP_STATE} from '../components/popup';

export const CATEGORIES = {
  MALE: 'male',
  FEMALE: 'female',
  ACCESSORIES: 'accessories',
  UNISEX: 'unisex'
};

export const externalAPI = {
  absolute: true
};

const MAX_VISIBLE_ITEMS_COUNT = 6;

function Store() {
  return (
    <div className='store store__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.title} main='store__main'>
        <Main />
      </StoreLayout>
    </div>
  );
}

export const getShopItemsList = (items) => {
  const itemsList = [];

  items.data.values?.forEach((item) => {
    const resultItem = {};
    resultItem.object = item;
    resultItem.title = item?.title;
    resultItem.img = LEAGUE_ORIGIN + item?.picture;
    resultItem.pictures =
      item?.pictures?.length > 0
        ? [resultItem.img, ...item.pictures.map((pic) => LEAGUE_ORIGIN + pic)]
        : null;
    resultItem.link = titles.others.product.link + '/' + item?.public_id;
    resultItem.art = item?.public_id;
    resultItem.price = item?.price;
    resultItem.chars = [...([item?.material, '', item?.description] || '')];
    resultItem.sizes = item?.proportions;
    resultItem.old_price = item?.old_price;
    resultItem.available =
      item?.proportions?.length > 1
        ? !!item.proportions.reduce((p, c) => p + c.count, 0)
        : !!item.proportions[0].count;

    resultItem.category = item?.group === 'clothes' ? item?.gender?.trim() : CATEGORIES.ACCESSORIES;

    itemsList.push(resultItem);
  });

  return itemsList;
};

function Main() {
  const [popupState, setPopupState] = useState(PRODUCT_ITEM_POPUP_STATE.HIDDEN);
  const [category, setCategory] = useState(CATEGORIES.MALE);
  const [filters, setFilters] = useState({prices: [], sizes: []});
  const [filtersReset, toggleFiltersReset] = useState(false);
  const [filtersApply, toggleFiltersApply] = useState(false);
  const [shopItems, setShopItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [visibleIndexes, setVisibleIndexes] = useState({
    start: 0,
    end: MAX_VISIBLE_ITEMS_COUNT - 1
  });
  const categoryItems = useMemo(
    () => shopItems.filter((item) => item.category === category),
    [category, shopItems]
  );

  const filteredCategoryItems = useMemo(
    () =>
      categoryItems
        .filter((item) =>
          filters.sizes.length
            ? item.sizes
                .map((itemSize) => itemSize.name)
                .filter((size) => filters.sizes.includes(size)).length
            : true
        )
        .filter((item) =>
          filters.prices.length
            ? item.price >= filters.prices?.[0] && item.price <= filters.prices?.[1]
            : true
        ),
    // prevent it from updating on each filter change (it should wait for an Apply button click)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryItems, filtersApply]
  );

  useEffect(() => {
    API.GET(ENDPOINT.league.shop, externalAPI).then((items) => {
      setShopItems(getShopItemsList(items));
      toggleFiltersApply((value) => !value);
    });
  }, []);

  useEffect(() => {
    setVisibleItems(filteredCategoryItems.slice(visibleIndexes.start, visibleIndexes.end + 1));
  }, [category, shopItems, visibleIndexes, filtersApply, filteredCategoryItems]);

  const handleFiltersReset = () => {
    setFilters({prices: [], sizes: []});

    setTimeout(() => {
      toggleFiltersReset((value) => !value);
      toggleFiltersApply((value) => !value);
    }, 0);
  };

  const handleFiltersApply = () => {
    toggleFiltersApply((value) => !value);

    document?.getElementById('main')?.scrollIntoView();
  };

  const handleCategoryUpdate = (category) => {
    setCategory(category);
    handleFiltersReset();

    document?.getElementById('main')?.scrollIntoView();
  };

  return (
    <>
      {popupState !== PRODUCT_ITEM_POPUP_STATE.HIDDEN && (
        <ProductAddPopup
          handler={() => setPopupState(PRODUCT_ITEM_POPUP_STATE.HIDDEN)}
          info={info.popup}
          popupState={popupState}
        />
      )}

      <div className='store__holder jcsb-aifs' id='main'>
        <div className='store__options'>
          <div className='store__options-categories advert column jcsb-aic'>
            <h4>{info.categories.title}</h4>

            <h5
              className={`store__options-categories-category ${
                category === CATEGORIES.MALE ? 'store__options-categories-category--current' : ''
              }`}
              onClick={() => handleCategoryUpdate(CATEGORIES.MALE)}
            >
              {info.categories.male}
            </h5>
            <h5
              className={`store__options-categories-category ${
                category === CATEGORIES.FEMALE ? 'store__options-categories-category--current' : ''
              }`}
              onClick={() => handleCategoryUpdate(CATEGORIES.FEMALE)}
            >
              {info.categories.female}
            </h5>
            <h5
              className={`store__options-categories-category ${
                category === CATEGORIES.UNISEX ? 'store__options-categories-category--current' : ''
              }`}
              onClick={() => handleCategoryUpdate(CATEGORIES.UNISEX)}
            >
              {info.categories.unisex}
            </h5>
            <h5
              className={`store__options-categories-category ${
                category === CATEGORIES.ACCESSORIES
                  ? 'store__options-categories-category--current'
                  : ''
              }`}
              onClick={() => handleCategoryUpdate(CATEGORIES.ACCESSORIES)}
            >
              {info.categories.accessories}
            </h5>
          </div>

          <div className='store__options-filters advert column jcsb-aifs'>
            <h4>{info.filters.title}</h4>
            <h5>{info.filters.size}</h5>
            <div className='store__options-filters--filters jcfs-aic wrap'>
              {info.filters.sizes.map((size, index) => (
                <div
                  onClick={() =>
                    setFilters((oldFilters) => ({
                      ...oldFilters,
                      sizes: oldFilters?.sizes?.includes(size)
                        ? oldFilters?.sizes.filter((item) => item !== size)
                        : [...oldFilters?.sizes, size]
                    }))
                  }
                  className={`store__options-filters--filter jcc-aic ${
                    filters.sizes.includes(size) ? 'filter--selected' : ''
                  }`}
                  key={index}
                >
                  {size}
                </div>
              ))}
            </div>
            <h5>{info.filters.price}</h5>

            <MultiRange
              min={
                categoryItems.reduce((prev, next) => (prev.price <= next.price ? prev : next), 0)
                  .price
              }
              max={
                categoryItems.reduce((prev, next) => (prev.price >= next.price ? prev : next), 0)
                  .price
              }
              setFilters={setFilters}
              filtersReset={filtersReset}
            />

            <button onClick={handleFiltersApply}>{info.filters.apply}</button>
            <button onClick={handleFiltersReset}>{info.filters.reset}</button>
          </div>

          <div className='store__options-ad advert hidden'>
            <div className='advert__photo'>
              <img src='/media/store/bannerleft.png' alt='bannerleft' />
            </div>
          </div>

          <div className='store__options-links desktop'>
            <Link to={titles.others.payment.link}>
              <h5>{info.links.payment}</h5>
            </Link>
            <Link to={titles.others.delivery.link}>
              <h5>{info.links.delivery}</h5>
            </Link>
            <Link to={titles.others.return.link}>
              <h5>{info.links.return}</h5>
            </Link>
            <Link to={titles.others.storeContacts.link}>
              <h5>{info.links.history}</h5>
            </Link>
          </div>
        </div>
        <div className='store__goods'>
          <div className='store__products jcfs-aifs wrap'>
            {visibleItems.map((item, index) => (
              <Product item={item} setPopupState={setPopupState} key={index} />
            ))}
          </div>

          <Pagination
            items={filteredCategoryItems}
            category={category}
            max={MAX_VISIBLE_ITEMS_COUNT}
            setVisibleIndexes={setVisibleIndexes}
            isVisible={
              filteredCategoryItems.length && filteredCategoryItems.length > MAX_VISIBLE_ITEMS_COUNT
            }
          />

          <div className='advert hidden'>
            <div className='advert__photo'>
              <img src={info.card.photo} alt='banner' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Product = ({item, setPopupState}) => {
  const {updateCartState} = useContext(CartContext);

  const handleButtonClick = () => {
    const isAvailable = updateCartState.addItem({...item, size: item?.sizes?.[0]});

    setPopupState(isAvailable ? PRODUCT_ITEM_POPUP_STATE.SUCCESS : PRODUCT_ITEM_POPUP_STATE.REJECT);
  };

  return (
    <div className='store__product'>
      <Link to={item.link}>
        <div className='store__product-photo'>
          <img src={item.img} alt={item.title} />

          {!item.available ? (
            <div className='store__product-photo--soldout'>
              {productInfo.text.not_available_short}
            </div>
          ) : null}
        </div>

        <div className='store__product-text advert column jcsb-aic'>
          <h5>{item.title}</h5>

          <div className='store__product-action w-full jcsb-aic'>
            <div className='store__product-price'>
              {item?.old_price ? (
                <div className='store__product-price--old'>{item.old_price} ₽</div>
              ) : null}
              <div>{item.price} ₽</div>
            </div>
          </div>
        </div>
      </Link>

      {item.available ? (
        <button onClick={handleButtonClick} className='store__product-price--button'>
          {info.cart}
        </button>
      ) : null}
    </div>
  );
};

export default Store;
