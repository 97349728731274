export const INTERNAL_ORIGIN =
  process.env.NODE_ENV === 'development' ? 'https://ocr.labab.ru' : window.location.origin;
export const MANAGER_ORIGIN = 'https://ocrrussia.com';
export const LEAGUE_ORIGIN = 'https://heroleague.ru';

const ENDPOINT = {
  token: '/csrf/',
  logout: '/auth/logout/',
  password_update: '/auth/me/password/',
  password_reset: '/auth/password_reset/',
  password_validate: '/auth/password_reset/validate_token/',
  password_confirm: '/auth/password_reset/confirm/',
  doping: '/doping/documents/',
  federation: '/federation/documents/',
  management: INTERNAL_ORIGIN + '/api/managements',
  offices: '/office/',
  regionalBranches: INTERNAL_ORIGIN + '/api/branches/list',
  partnersCards: INTERNAL_ORIGIN + '/api/page/banner/partners',
  partners: '/partner/',
  qualifying_races: '/qualifying_races/',
  accredited_races: '/accredited_races/',
  calendar: '/calendar/',
  participant: '/participant/',
  participant_me: '/participant/me/',
  participant_login: '/auth/participant/login/',
  organizer: '/organiser/',
  organizer_me: '/organiser/me/',
  organizer_login: '/auth/organiser/login/',
  subscription: '/participant/subscription/',
  event: '/event/',
  organizers: '/organisers/',
  news_subscription: LEAGUE_ORIGIN + '/api/user/subscribe/news',
  forms: {
    feedback: '/forms/feedback/',
    participant: '/forms/participant/',
    partner: '/forms/partner/',
    regional: '/forms/regional/'
  },
  league: {
    shop: LEAGUE_ORIGIN + '/api/shop/project/ocr',
    regions: LEAGUE_ORIGIN + '/api/location/region/list/RU',
    cities: LEAGUE_ORIGIN + '/api/location/city/region/',
    delivery_price: LEAGUE_ORIGIN + '/api/shop/delivery/price',
    payment: LEAGUE_ORIGIN + '/api/payment',
    location: LEAGUE_ORIGIN + '/api/location/detailed'
  },
  manager: {
    teams: MANAGER_ORIGIN + '/api/members/group',
    news: MANAGER_ORIGIN + '/api/news',
    news_article: MANAGER_ORIGIN + '/api/news/one'
  }
};

export default ENDPOINT;
