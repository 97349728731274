import {StoreLayout} from '../../components/layout';
import info from '../../../info/ru/delivery.json';

function Delivery() {
  return (
    <div className='article article__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.title} main='article__main jcc-aic wrap'>
        <Main />
      </StoreLayout>
    </div>
  );
}

function Main() {
  return (
    <div className='article__holder jcfs-aifs wrap'>
      <h2>Сроки доставки</h2>
      <p style={{marginBottom: 0}}>
        Доставка осуществляется в течение 2-3 рабочих дней с момента оформления заказа при условии
        наличия товара на сайте. Исключением является именная продукция, доставка которой
        осуществляется в течение 2 недель с момента подтверждения именной надписи для нанесения на
        товар.
      </p>
      <p style={{marginTop: 0}}>
        <b>Важно!</b>
        <br />
        <span>Сроки доставки также зависят от региона доставки заказа.</span>
      </p>
      <h4 style={{marginBottom: '0.6vw'}}>Способы доставки</h4>
      <p style={{margin: 0}}>Наш интернет-магазин предлагает несколько вариантов доставки:</p>
      <p>
        1) Курьерская <br /> Вы можете заказать доставку товара с помощью курьера, который прибудет
        по указанному адресу. Курьерская служба, после поступления заказа, свяжется с вами и
        предложит выбрать удобное время доставки и уточнит адрес.
      </p>
      <p>
        2) Пункт выдачи заказа <br /> Вы можете забрать товар в одном из пунктов выдачи товаров.
        Список адресов появится в корзине при выборе этого вида доставки.
      </p>
      <p>
        3) Почта России <br /> Если в вашем городе не действует курьерская служба, то вы можете
        заказать доставку через отделение почты. Сразу по прибытии товара, на ваш адрес придет
        извещение о посылке.
      </p>
    </div>
  );
}

export default Delivery;
