import {StoreLayout} from '../components/layout';
import info from '../../info/ru/product.json';
import storeInfo from '../../info/ru/store.json';
import {ProductAddPopup, ProductPopup, PRODUCT_ITEM_POPUP_STATE} from '../components/popup';

import {HashLink} from 'react-router-hash-link';
import {useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {getShopItemsList, externalAPI, CATEGORIES} from './store';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';
import CartContext from './cartContext';

const shuffleArray = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

function Product() {
  return (
    <div className='product product__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.title} main='product__main'>
        <Main />
      </StoreLayout>
    </div>
  );
}

function Main() {
  const [showPopup, setShowPopup] = useState(false);
  const [productItemPopupState, setProductItemPopupState] = useState(
    PRODUCT_ITEM_POPUP_STATE.HIDDEN
  );
  const [product, setProduct] = useState({});
  const [productSize, setProductSize] = useState({});
  const [recommended, setRecommended] = useState([]);
  const {id: productID} = useParams();
  const {updateCartState} = useContext(CartContext);

  useEffect(() => {
    API.GET(ENDPOINT.league.shop, externalAPI).then((items) => {
      const shopItems = getShopItemsList(items);
      const productItem = shopItems.filter((item) => item.art === productID)[0];

      setProduct(productItem);
      setProductSize(productItem?.sizes?.[0]);
      setRecommended(
        shuffleArray(
          shopItems.filter((item) => item.art !== productID && item.category === product.category)
        ).slice(0, 4)
      );
    });
  }, [product.category, productID]);

  const handleAddButtonClick = (updatedItems) => {
    const isAvailable = updateCartState.addItem(updatedItems);

    setProductItemPopupState(
      isAvailable ? PRODUCT_ITEM_POPUP_STATE.SUCCESS : PRODUCT_ITEM_POPUP_STATE.REJECT
    );
  };

  return (
    <>
      {showPopup && (
        <ProductPopup setter={setShowPopup} info={info.popup} category={product?.category} />
      )}

      {productItemPopupState !== PRODUCT_ITEM_POPUP_STATE.HIDDEN && (
        <ProductAddPopup
          handler={() => setProductItemPopupState(PRODUCT_ITEM_POPUP_STATE.HIDDEN)}
          info={storeInfo.popup}
          popupState={productItemPopupState}
        />
      )}

      <div className='product__holder jcsb-aifs'>
        <div className='product__photo'>
          {product?.pictures ? (
            <ProductImages images={product?.pictures} title={product?.title} />
          ) : (
            <img src={product?.img} alt={product?.title} />
          )}
        </div>
        <div className='product__data'>
          <h2 className='product__title'>{product?.title}</h2>
          <h5>
            {info.text.art}: {product?.art}
          </h5>
          <div className='product__char'>
            <h5 className='product__char-title'>{info.text.chars}:</h5>
            <h5 className='product__char-text'>
              {product?.chars?.map((char, index) => (
                <span key={index}>
                  {char} <br />
                </span>
              ))}
            </h5>
          </div>
          <div className='product__price'>
            <h5 className='product__price-title'>{info.text.price}:</h5>

            <h2>
              {product?.price} ₽{' '}
              {product?.old_price ? (
                <span className='product__price--old'>{product?.old_price} ₽</span>
              ) : null}
            </h2>
          </div>

          {product?.sizes?.length &&
          product.available &&
          product?.category !== CATEGORIES.ACCESSORIES ? (
            <div className='product__size'>
              <h5>{info.text.sizes}:</h5>
              <div className='store__options-filters--filters jcfs-aic wrap'>
                {product?.sizes
                  ?.filter((size) => size.count)
                  .map((size, index) => (
                    <div
                      onClick={() => setProductSize(size)}
                      className={`store__options-filters--filter jcc-aic ${
                        size.name === productSize.name ? 'filter--selected' : ''
                      }`}
                      key={index}
                    >
                      {size.name}
                    </div>
                  ))}
              </div>
              <h5 className='product__size-table' onClick={() => setShowPopup(true)}>
                {info.text.table}
              </h5>
            </div>
          ) : null}

          <div className='product__action flex wrap'>
            {product.available ? (
              <button onClick={() => handleAddButtonClick({...product, size: productSize})}>
                {info.text.add}
              </button>
            ) : null}

            <h5 className='product__available inline'>
              {product.available ? info.text.available : info.text.not_available}
            </h5>
            <h5 className='product__delivery w-full'>{info.text.delivery}</h5>
          </div>
        </div>
      </div>
      <div className='product__recommend'>
        <h2>{info.text.recommended}:</h2>
        <div className='store__products jcfs-aifs wrap'>
          {recommended?.map((item, index) => (
            <div className='store__product' key={index}>
              <HashLink to={item.link + '#product__main'}>
                <div className='store__product-photo'>
                  <img src={item.img} alt={item.title} />

                  {!item.available ? (
                    <div className='store__product-photo--soldout'>
                      {info.text.not_available_short}
                    </div>
                  ) : null}
                </div>
                <div className='store__product-text advert column jcsb-aic'>
                  <h5>{item.title}</h5>
                  <div className='store__product-action w-full jcsb-aic'>
                    <div className='store__product-price'>
                      {item?.old_price ? (
                        <div className='product__price--old'>{item?.old_price} ₽</div>
                      ) : null}
                      <div className='store__product-price--current'>{item.price} ₽</div>
                    </div>
                  </div>
                </div>
              </HashLink>

              {item.available ? (
                <button
                  onClick={() => handleAddButtonClick({...item, size: item?.sizes?.[0]})}
                  className='store__product-price--button'
                >
                  {info.text.short_add}
                </button>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const ProductImages = ({images, title}) => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    setCurrentImage(images[0]);
  }, [images]);

  return (
    <>
      <img src={currentImage} alt={title} />

      <div className='product__photo-images'>
        {images.map((image) => (
          <img
            src={image}
            alt={title}
            key={image}
            onClick={() => setCurrentImage(image)}
            className={`product__photo-image ${
              image === currentImage ? 'product__photo-image--current' : ''
            }`}
          />
        ))}
      </div>
    </>
  );
};

export default Product;
