import {useCallback, useEffect, useRef} from 'react';
import info from '../../../info/ru/store.json';

function fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
  const rangeDistance = to.max - to.min;
  const fromPosition = from.value - to.min;
  const toPosition = to.value - to.min;

  controlSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
      ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
      ${rangeColor} ${(toPosition / rangeDistance) * 100}%, 
      ${sliderColor} ${(toPosition / rangeDistance) * 100}%, 
      ${sliderColor} 100%)`;
}

function setToggleAccessible(currentTarget, toSlider) {
  if (Number(currentTarget.value) <= 0) {
    toSlider.style.zIndex = 2;
  } else {
    toSlider.style.zIndex = 0;
  }
}

export function MultiRange({min = 0, max = 0, filtersReset = false, setFilters = () => {}}) {
  const fromSlider = useRef(null);
  const toSlider = useRef(null);
  const fromInput = useRef(null);
  const toInput = useRef(null);

  const updateFilters = useCallback(
    () =>
      setFilters((filters) => ({
        ...filters,
        prices: [fromSlider.current.value, toSlider.current.value]
      })),
    [setFilters]
  );

  const controlFromInput = (value) => {
    if (parseInt(value) >= parseInt(toInput.current.value)) {
      fromSlider.current.value = toInput.current.value;
      fromInput.current.value = toInput.current.value;
    } else {
      fromSlider.current.value = value;
      fromInput.current.value = value;
    }

    fillSlider(fromInput.current, toInput.current, '#C6C6C6', '#25daa5', toSlider.current);
    updateFilters();
  };

  const controlToInput = (value) => {
    if (parseInt(fromInput.current.value) <= parseInt(value)) {
      toSlider.current.value = value;
      toInput.current.value = value;
    } else {
      toSlider.current.value = fromInput.current.value;
      toInput.current.value = fromInput.current.value;
    }

    fillSlider(fromInput.current, toInput.current, '#C6C6C6', '#25daa5', toSlider.current);
    setToggleAccessible(toSlider.current, toSlider.current);
    updateFilters();
  };

  const controlFromSlider = useCallback(
    (value) => {
      if (parseInt(value) >= parseInt(toInput.current.value)) {
        fromSlider.current.value = toInput.current.value;
        fromInput.current.value = toInput.current.value;
      } else {
        fromSlider.current.value = value;
        fromInput.current.value = value;
      }

      fillSlider(fromSlider.current, toSlider.current, '#C6C6C6', '#315fb6', toSlider.current);
      updateFilters();
    },
    [updateFilters]
  );

  const controlToSlider = useCallback(
    (value) => {
      if (parseInt(fromInput.current.value) <= parseInt(value)) {
        toSlider.current.value = value;
        toInput.current.value = value;
      } else {
        toSlider.current.value = fromInput.current.value;
        toInput.current.value = fromInput.current.value;
      }

      fillSlider(fromSlider.current, toSlider.current, '#C6C6C6', '#315fb6', toSlider.current);
      setToggleAccessible(toSlider.current, toSlider.current);
      updateFilters();
    },
    [updateFilters]
  );

  useEffect(() => {
    controlToSlider(max);
    controlFromSlider(min);
  }, [min, max, controlToSlider, controlFromSlider, filtersReset]);

  return (
    <>
      <div className='store__options-filters--range-wrapper'>
        <input
          ref={fromSlider}
          className='store__options-filters--range--from jcsb-aic'
          type='range'
          onChange={(e) => controlFromSlider(e.target.value)}
          defaultValue={min}
          min={min}
          max={max}
        />
        <input
          ref={toSlider}
          className='store__options-filters--range--to jcsb-aic'
          type='range'
          onChange={(e) => controlToSlider(e.target.value)}
          defaultValue={max}
          min={min}
          max={max}
        />
      </div>

      <div className='store__options-filters--price jcsb-aic'>
        <div className='store__options-filters--price-from'>
          <h5>{info.filters.from}</h5>
          <input
            ref={fromInput}
            type='text'
            placeholder={min}
            onChange={(e) => controlFromInput(e.target.value)}
            defaultValue={min}
            min={min}
            max={max}
          />
        </div>
        <div className='store__options-filters--price-to'>
          <h5>{info.filters.to}</h5>
          <input
            ref={toInput}
            type='text'
            placeholder={max}
            onChange={(e) => controlToInput(e.target.value)}
            defaultValue={max}
            min={min}
            max={max}
          />
        </div>
      </div>
    </>
  );
}
