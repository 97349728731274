import {BannerLayout} from '../components/layout';
import {ContactsPopup, ContactsPopupEndpoint} from '../components/popup';
import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';
import info from '../../info/ru/offices.json';
import {externalAPI} from './store';

function Offices() {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    API.GET(ENDPOINT.regionalBranches, externalAPI).then((response) => setBranches(response.data));
  }, []);

  return (
    <div className='offices offices__container jcc-aic wrap'>
      <BannerLayout background={info?.banner} title={info?.title}>
        <Main branches={branches} />
      </BannerLayout>
    </div>
  );
}

function Main({branches}) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className='offices__main'>
      {showPopup && (
        <ContactsPopup
          setter={setShowPopup}
          info={info?.popup}
          endpoint={ContactsPopupEndpoint.REGIONAL}
        />
      )}

      <Table branches={branches} />

      <div className='advert jcsb-aic'>
        <div className='advert__text'>
          <h3>{info?.card?.text}</h3>
          <button onClick={() => setShowPopup(true)}>{info?.card?.button}</button>
        </div>
        <div className='advert__photo'>
          <img src='/media/offices/card.png' alt='card' />
        </div>
      </div>
    </div>
  );
}

function Table({branches}) {
  return (
    <table className='table'>
      <thead>
        <tr>
          <th>№</th>
          {info?.table?.titles?.map((title) => (
            <th key={title}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {branches?.length > 0 &&
          branches.map((region, index) => (
            <tr key={index}>
              <td>{index + 1}</td>

              <td key={index} className={`table__${region?.phone}`}>
                {region?.name}
              </td>
              <td key={index} className={`table__${region?.phone}`}>
                {region?.person}
              </td>
              <td key={index} className={`table__${region?.phone}`}>
                <a href={`tel:${region?.phone}`}>{region?.phone}</a>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default Offices;
