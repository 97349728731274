import info from '../../../info/ru/cabinet.json';

export default function History() {
  return (
    <>
      <h2 className='cabinet__heading'>{info.history.heading}</h2>

      <div className='cabinet__history-buttons'>
        <button className='cabinet__history-buttons--current'>{info.history.filters.all}</button>
        <button>{info.history.filters.current}</button>
        <button>{info.history.filters.finished}</button>
        <button>{info.history.filters.cancelled}</button>
      </div>
      {info.history.items.map((item, index) => {
        return (
          <div className='cabinet__history-item jcsb-aic wrap' key={index}>
            <div className='cabinet__history-data jcsb-aic'>
              <div className='cabinet__history-data--logo'>
                <img src='/media/cart/product.png' alt='product' />
              </div>
              <div className='cabinet__history-data--first column jcsb-aifs'>
                <div className='cabinet__history-data--block jcfs-aic wrap'>
                  <h5>{info.history.text.title}:</h5>
                  <p>{item.title}</p>
                </div>
                <div className='cabinet__history-data--block jcfs-aic wrap'>
                  <div className='jcsb-aifs'>
                    <div className=''>
                      <h5>{info.history.text.size}:</h5>
                      <p>{item.size}</p>
                    </div>
                    <div className=''>
                      <h5>{info.history.text.art}:</h5>
                      <p>{item.art}</p>
                    </div>
                  </div>

                  <h5>{info.history.text.count}:</h5>
                  <p className='inline'>{item.count}</p>
                </div>
              </div>
              <div className='cabinet__history-data--second column jcsb-aifs'>
                <div className='cabinet__history-data--block '>
                  <h5>{info.history.text.name}:</h5>
                  <p>{item.name}</p>
                </div>
                <div className='cabinet__history-data--block '>
                  <h5>{info.history.text.delivery_status}:</h5>
                  <p>{item.delivery_status}</p>
                </div>
                <div className='cabinet__history-data--block '>
                  <h5>{info.history.text.address}:</h5>
                  <p>{item.address}</p>
                </div>
              </div>
              <div className='cabinet__history-data--third column jcsb-aife'>
                <div className='cabinet__history-data--block '>
                  <h5>{info.history.text.order_date}:</h5>
                  <p>{item.order_date}</p>
                </div>
                <div className='cabinet__history-data--block '>
                  <h5>{info.history.text.order_status}:</h5>
                  <p>{item.order_status}</p>
                </div>
                <div className='cabinet__history-data--block '>
                  <h5>{info.history.text.price}:</h5>
                  <h3>{item.price}</h3>
                </div>
              </div>
            </div>
            <div className='cabinet__history-action w-full jcfe-aic'>
              <button className='inactive'>{info.history.text.cancel}</button>
              <button>{info.history.text.repeat}</button>
            </div>
          </div>
        );
      })}
    </>
  );
}
