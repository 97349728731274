import {useState} from 'react';
import Input, {INPUT_TYPE} from '../../components/form/input';
import info from '../../../info/ru/cabinet.json';
import titles from '../../../info/titles.json';
import API from '../../functions/api';
import ENDPOINT from '../../../info/endpoints';
import {SECTION} from './cabinet';
import {CabinetPopup} from '../../components/popup';
import {useContext, useCallback} from 'react';
import EventContext from './eventContext';
import {pageReload} from '../../functions/pageReload';
import {Link, useNavigate} from 'react-router-dom';

const EVENT_METHOD = {
  POST: 'event_create',
  PATCH: 'event_change',
  DELETE: 'event_cancel'
};

export default function Event({section}) {
  const {eventState} = useContext(EventContext);

  const navigate = useNavigate();

  const [errorData, setErrorData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputData, setInputData] = useState(eventState);
  const [popupState, setPopupState] = useState(false);
  const [eventMethod, setEventMethod] = useState(EVENT_METHOD.POST);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    if (query?.logo?.size === 0) {
      delete query?.logo;
    }

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    eventMethod === EVENT_METHOD.POST &&
      (await API.POST(ENDPOINT.event, query, options)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setErrorData({});
            setPopupState(EVENT_METHOD.POST);
          }
        })
        .catch((error) => {
          setErrorData(error?.response?.data);
        }));

    eventMethod === EVENT_METHOD.PATCH &&
      (await API.PATCH(inputData?.change_url, query, options)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setErrorData({});
            setPopupState(EVENT_METHOD.PATCH);
          }
        })
        .catch((error) => {
          setErrorData(error?.response?.data);
        }));

    eventMethod === EVENT_METHOD.DELETE &&
      (await API.DELETE(inputData?.cancel_url)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setErrorData({});
            setPopupState(EVENT_METHOD.DELETE);
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.detail);
        }));

    setIsDisabled(false);
  };

  const disableIfEnabled = useCallback(
    () => setIsDisabled((isDisabled) => (isDisabled ? false : isDisabled)),
    [setIsDisabled]
  );

  const handleInputChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value
    });

    setIsDisabled((isDisabled) => (isDisabled ? false : isDisabled));
  };

  return (
    <>
      {popupState ? (
        <CabinetPopup
          handler={() => pageReload(navigate(titles.others.cabinet.link))}
          info={info[popupState].popup}
        />
      ) : null}

      <div className='cabinet__heading-wrapper'>
        {inputData?.logo ? (
          <img className='cabinet__heading-logo' src={inputData.logo} alt='logo' />
        ) : null}

        <h2 className='cabinet__heading'>{info[section].heading}</h2>
      </div>

      <form className='cabinet__event advert' onSubmit={handleSubmit}>
        <div className='login__form jcsb-aifs wrap'>
          <div className='login__form-left'>
            <Input
              value={inputData?.title || ''}
              onChange={handleInputChange}
              text={info.event.form.title + '*'}
              type='text'
              error={errorData?.title}
              name='title'
              required
            />
            <Input
              value={inputData?.start_date || ''}
              onChange={handleInputChange}
              text={info.event.form.date + '*'}
              type='date'
              error={errorData?.start_date}
              name='start_date'
              required
            />
            <Input
              value={inputData?.finish_date || ''}
              onChange={handleInputChange}
              text={info.event.form.date_finish}
              type='date'
              error={errorData?.finish_date}
              name='finish_date'
            />
            <Input
              value={inputData?.link || ''}
              onChange={handleInputChange}
              text={info.event.form.link + '*'}
              type='text'
              error={errorData?.link}
              name='link'
              required
            />
          </div>
          <div className='login__form-right'>
            <Input
              value={inputData?.city || ''}
              onChange={handleInputChange}
              text={info.event.form.city + '*'}
              type='text'
              error={errorData?.city}
              name='city'
              required
            />
            <Input
              value={inputData?.location || ''}
              onChange={handleInputChange}
              text={info.event.form.location + '*'}
              type='text'
              error={errorData?.location}
              name='location'
              required
            />
            <Input
              text={info.event.form.logo + (section === SECTION.EVENT_CREATE ? '*' : '')}
              onChange={disableIfEnabled}
              type='file'
              error={errorData?.logo}
              name='logo'
              required={section === SECTION.EVENT_CREATE}
            />
          </div>

          <Input
            value={inputData?.subtitle || ''}
            onChange={handleInputChange}
            text={info.event.form.format + '*'}
            name='subtitle'
            error={errorData?.subtitle}
            inputType={INPUT_TYPE.TEXTAREA}
          />

          <h4 className='w-full'>
            {inputData?.cancel_url ? info.event.form.not_editable : info.event.form.looking}
          </h4>

          {section === SECTION.EVENT_CREATE && (
            <button
              type='submit'
              className='inactive'
              disabled={isDisabled}
              onClick={() => setEventMethod(EVENT_METHOD.POST)}
            >
              {info.event.form.send}
            </button>
          )}

          {section === SECTION.EVENT_CHANGE && (
            <div className='login__form-action login__form-action--bottom jcsb-aic'>
              {inputData?.change_url ? (
                <button
                  type='submit'
                  className='inactive'
                  disabled={isDisabled}
                  onClick={() => setEventMethod(EVENT_METHOD.PATCH)}
                >
                  {info.event.form.change}
                </button>
              ) : null}

              {inputData?.cancel_url ? (
                <>
                  <button type='button'>
                    <Link to={titles.others.cabinet.section.event_list.link}>
                      {info.event.form.close}
                    </Link>
                  </button>

                  <button
                    type='submit'
                    className='inactive'
                    onClick={() => setEventMethod(EVENT_METHOD.DELETE)}
                  >
                    {info.event.form.cancel}
                  </button>
                </>
              ) : null}
            </div>
          )}
        </div>
      </form>
    </>
  );
}
