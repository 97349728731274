import ENDPOINT from '../../../info/endpoints';
import info from '../../../info/ru/cabinet.json';
import API from '../../functions/api';

const daysBeforeExpiracyNotification = 7;

const checkExpiration = (date) => {
  const diff = new Date(date).getTime() - new Date().getTime();
  const expired = diff <= 0;
  const isAboutToExpire = diff <= new Date(daysBeforeExpiracyNotification * 24 * 60 * 60 * 1000);

  return {expired, isAboutToExpire};
};

export default function Licenses({data}) {
  const expiracy = checkExpiration(data?.expiry_dt);

  const handleButtonClick = async () =>
    await API.POST(ENDPOINT.subscription)
      .then(async (response) => {
        if (response.status >= 200 && response.status < 300) {
          const params = new URLSearchParams();

          Object.entries(response.data?.payload).forEach((entry) =>
            params.append(entry[0], entry[1])
          );

          window.location.replace(response.data?.pay_url + '?' + params.toString());
        }
      })
      .catch((error) => {
        console.log(error);
      });

  return (
    <>
      <h2 className='cabinet__heading'>{info.licenses.heading}</h2>

      <div className='cabinet__licenses'>
        <div className='cabinet__licenses-license column jcfs-aic'>
          <img
            className='cabinet__licenses-license-image'
            src='/media/cabinet/card_logo.svg'
            alt='ocr_logo'
          />

          <div className='cabinet__licenses-license-data jcsb-aic w-full'>
            <div className='cabinet__licenses-license-index'>
              {data ? data?.card_number : '****'}
            </div>
            <div className={'cabinet__licenses-license-expiration'}>
              до{' '}
              <span className={expiracy.expired ? 'cabinet__licenses-license--expired' : ''}>
                {data ? data?.expiry_str : '**/**'}
              </span>
            </div>
          </div>
        </div>

        {(expiracy.isAboutToExpire || !data) && (
          <div className='cabinet__licenses-button'>
            <button type='button' onClick={handleButtonClick}>
              {!data ? info.licenses.button.buy : info.licenses.button.renew}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
