import {BannerLayout} from '../components/layout';
import info from '../../info/ru/contribution.json';
import titles from '../../info/titles.json';
import {useContext, useState} from 'react';
import {ContributionPopup} from '../components/popup';
import device from '../functions/device';
import Slick from 'react-slick';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import AuthContext, {AuthState} from '../functions/auth';

export const NAVIGATE_TO_LICENSES_COOKIE = 'navigateToLicenses';

function Contribution() {
  return (
    <div className='contribution contribution__container jcc-aic wrap'>
      <BannerLayout background={info.banner} title={info.title}>
        <Main />
      </BannerLayout>
    </div>
  );
}

function Main() {
  return (
    <div className='contribution__main'>
      <Advert />

      <h2 className='contribution__heading'>{info.privilege.title}</h2>

      <Slider />
    </div>
  );
}

function Advert() {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const {authState} = useContext(AuthContext);

  return (
    <div className='advert jcsb-aic'>
      {showPopup && <ContributionPopup setter={setShowPopup} info={info.popup} />}

      <div className='advert__photo'>
        <img src='/media/contribution/card.png' alt='card' />
      </div>
      <div className='advert__text column jcsb-aifs'>
        <h2>{info.card.title}</h2>
        <h4>{info.card.text}</h4>
        <h5>{info.card.subtext}</h5>
        <div className='contribution__price'>
          <span>{info.card.price.title}:</span>
          <h2 className='inline' data-value={info.card.price.value}>
            {info.card.price.text}
          </h2>
          <h5 className='inline'>{info.card.price.sign}</h5>
        </div>
        <button
          onClick={() => {
            Cookies.set(NAVIGATE_TO_LICENSES_COOKIE, 'true');

            navigate(
              authState === AuthState.DEFAULT
                ? titles.others.signup.link
                : titles.others.cabinet.section.licenses.link
            );
          }}
        >
          {info.card.button}
        </button>
      </div>
    </div>
  );
}

function Slider() {
  const config = {
    className: 'contribution__slider',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />
  };
  const mob_config = {...config, slidesToShow: 1, slidesToScroll: 1};

  let sliderConfig = device('mobile') ? {...mob_config} : {...config};

  function Arrow(props) {
    const {className, onClick} = props;

    return (
      <div className={className} onClick={onClick}>
        <img src='/media/home/arrow.png' alt='arrow' />
      </div>
    );
  }

  return (
    <Slick {...sliderConfig}>
      {Object.values(info.privilege.slider).map((slide, index) => (
        <div className='cards__card column jcsb-aifs' key={index}>
          <div className='contribution__card-header'>
            <div className='cards__card-logo jcfs-aic'>
              <img src={slide.logo} alt={slide.logo} />
            </div>

            <h4 className='contribution__card-title'>{slide.text}</h4>
          </div>

          <h5>{slide.subtext}</h5>

          <div className='contribution__card-footer column jcsb-aifs'>
            <div className='contribution__card-footer--text'>
              <h4 className='contribution__card-sale'>{slide.sale}</h4>

              <h5>{slide.range}</h5>
            </div>

            <button className='w-full'>
              <a href={slide.link} target='_blank' rel='noreferrer'>
                {info.privilege.button}
              </a>
            </button>
          </div>
        </div>
      ))}
    </Slick>
  );
}

export default Contribution;
