import {BannerLayout} from '../components/layout';

import info from '../../info/ru/team.json';
import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT, {MANAGER_ORIGIN} from '../../info/endpoints';
import {externalAPI} from './store';
import Popup from '../components/popup';
import DOMPurify from 'dompurify';

function Team() {
  return (
    <div className='team team__container jcc-aic wrap'>
      <BannerLayout background={info.banner} title={info.title}>
        <Main />
      </BannerLayout>
    </div>
  );
}

function Main() {
  const [teamData, setTeamData] = useState([]);
  const [currentMember, setCurrentMember] = useState(null);

  useEffect(() => {
    API.GET(ENDPOINT.manager.teams, externalAPI).then((teamsResponse) => {
      API.GET(
        `${ENDPOINT.manager.teams}/${teamsResponse?.data?.values?.[0]?.public_id}`,
        externalAPI
      ).then((teamResponse) => {
        setTeamData(teamResponse?.data?.values);
      });
    });
  }, []);

  const addImageFallback = (event) => {
    event.currentTarget.src = '/media/team/fallback.jpeg';
  };

  return (
    <div className='team__main'>
      {currentMember ? (
        <Popup setter={setCurrentMember}>
          <div className='advert partners__card'>
            <div className='advert__photo'>
              <img src={MANAGER_ORIGIN + currentMember?.picture} alt={currentMember?.name} />
            </div>

            <div className='advert__text'>
              <div className='cards__card-title'>
                {info.team.title}: <b>{currentMember?.title}</b>
              </div>

              <p className='cards__card-subtitle'>{currentMember?.name}</p>

              <div className='advert__data'>
                {currentMember?.birthday ? (
                  <p>
                    <b>{info.team.birthday}</b>
                    <br />
                    {new Date(currentMember.birthday * 1000).toLocaleString('ru', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </p>
                ) : null}

                {currentMember?.region ? (
                  <p>
                    <b>{info.team.region}</b> {currentMember.region.name_ru}
                  </p>
                ) : null}

                {currentMember?.company ? (
                  <p>
                    <b>{info.team.organisation}</b> {currentMember.company}
                  </p>
                ) : null}

                {currentMember?.agent_name ? (
                  <p>
                    <b>{info.team.coach}</b> {currentMember.agent_name}
                  </p>
                ) : null}

                {currentMember?.description ? (
                  <div
                    className=''
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(currentMember.description)
                    }}
                  ></div>
                ) : null}
              </div>

              <button onClick={() => setCurrentMember(null)}>{info.team.close}</button>
            </div>
          </div>
        </Popup>
      ) : null}

      <div className='team__team cards jcfs-aifs wrap w-full'>
        {teamData.map((team) => (
          <div className='cards__card column jcsb-aifs' key={team.public_id}>
            <div className='cards__card-logo w-full jcc-aic'>
              <img
                src={MANAGER_ORIGIN + team?.picture}
                onError={addImageFallback}
                alt={team?.name}
              />
            </div>

            <div className='cards__card-data'>
              <div className='cards__card-title'>
                {info.team.title}: <br />
                <b>{team?.title}</b>
              </div>

              <p className='cards__card-subtitle'>{team.name}</p>

              <div className='cards__card-button'>
                <button className='w-full' onClick={() => setCurrentMember(team)}>
                  {info.team.button}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
