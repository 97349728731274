import info from '../../info/ru/footer.json';
import titles from '../../info/titles.json';
import links from '../../info/links.json';
import {Icon} from '@iconify/react';
import tg from '@iconify/icons-bx/bxl-telegram';
import vk from '@iconify/icons-bx/bxl-vk';

import {Link} from 'react-router-dom';

function Footer() {
  return (
    <footer className='footer jcsb-aic column'>
      <div className='footer__top w-full jcsb-aic'>
        <Link className='footer__logo' to='/'>
          <img src='/media/logo.svg' alt='logo' />
        </Link>
        <div className='footer__top-rignt jcsb-aic'>
          <div className='footer__lang'>
            <p>
              {info.lang}: <span className='footer__lang-current'>RU</span> | <span>EN</span>
            </p>
          </div>
          <div className='footer__social jcfe-aic'>
            <a
              href={links.tg}
              className='footer__social-logo footer__social-logo--tg'
              target='_blank'
              rel='noreferrer'
            >
              <Icon icon={tg} style={{color: '#3262aa'}} />
            </a>
            <a
              href={links.vk}
              className='footer__social-logo footer__social-logo--vk'
              target='_blank'
              rel='noreferrer'
            >
              <Icon icon={vk} style={{color: '#3262aa'}} />
            </a>
          </div>
        </div>
      </div>
      <div className='footer__logos jcfs-aic w-full wrap'>
        <p className='footer__logos-title'>{info.payment}:</p>

        <div className='footer__logos-wrapper jcfs-aic w-full'>
          <a
            className='footer__logos-logo footer__logos-logo--ut'
            href='https://www.uniteller.ru/'
            target='_blank'
            rel='noreferrer'
          >
            <img src='/media/payment/uniteller.png' alt='uniteller' />
          </a>
          <div className='footer__logos-logo footer__logos-logo--mc'>
            <img src='/media/payment/mastercard.png' alt='mastercard' />
          </div>
          <div className='footer__logos-logo footer__logos-logo--mir'>
            <img src='/media/payment/mir.png' alt='mir' />
          </div>
          <div className='footer__logos-logo footer__logos-logo--visa'>
            <img src='/media/payment/visa.png' alt='visa' />
          </div>
        </div>
      </div>
      <div className='footer__middle w-full'>
        <div className='footer__titles wrap jcfs-aic'>
          {Object.values(titles.titles).map((title) => {
            return (
              <div className='footer__titles-title' key={title.title}>
                <Link to={title.link}>{title.title}</Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className='footer__bottom w-full jcsb-aife'>
        <div className='footer__bottom-design jcc-aifs column'>
          {info.design}:
          <img src='/media/design.svg' alt='design' />
        </div>
        <div className='footer__bottom-rights'>{info.rights}</div>
      </div>
    </footer>
  );
}

export default Footer;
