import info from '../../info/ru/protocols.json';

export const PREVIEW = {
  excel: '/media/federation/excel.svg',
  pdf: '/media/federation/pdf.svg',
  word: '/media/federation/word.svg',
  folder: '/media/federation/folder.svg'
};

const defaultPreview = '/media/federation/doc2.png';

const Cards = ({json, handleClick = null}) => {
  return (
    <div className='cards jcfs-aifs wrap w-full'>
      {json?.cards?.length > 0
        ? json?.cards?.map((card, index) => (
            <div className='cards__card column jcsb-aifs' key={index}>
              <div className='cards__card-logo w-full jcc-aic'>
                <img
                  src={card?.logo || PREVIEW[card?.type] || defaultPreview}
                  className={PREVIEW[card?.type] ? 'cards__card-logo--document' : ''}
                  alt={card?.title}
                />
              </div>

              <div className='cards__card-title'>{card?.title}</div>

              {card?.date ? (
                <p className='cards__card-subtitle'>{`${info.cards.date}: ${card.date}`}</p>
              ) : null}

              {card?.section ? (
                <p className='cards__card-subtitle'>{`${info.cards.section}: ${card.section}`}</p>
              ) : null}

              <div className='cards__card-button'>
                {card?.link ? (
                  <a href={card?.link} target='_blank' rel='noreferrer'>
                    {json?.button}
                  </a>
                ) : (
                  <p onClick={() => handleClick(card)}>{json?.button}</p>
                )}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};
export default Cards;
