import {LoginLayout} from '../../components/layout';
import info from '../../../info/ru/signup.json';
import titles from '../../../info/titles.json';
import tshirt_size from '../../../info/ru/tshirt_size.json';
import regions from '../../../info/ru/regions.json';
import sex from '../../../info/ru/sex.json';

import {Link, useNavigate} from 'react-router-dom';
import {useState, useContext, useEffect} from 'react';
import ENDPOINT from '../../../info/endpoints';
import API from '../../functions/api';
import AuthContext, {AuthState} from '../../functions/auth';
import Input, {INPUT_TYPE} from '../../components/form/input';
import {SignupPopup} from '../../components/popup';

export const SignupState = {
  SPORTSMAN: 'form_sportsman',
  ORGANIZER: 'form_organizer',
  DEFAULT: 'form_default'
};

const TabState = {
  LOGIN: 'login',
  SIGNUP: 'registration'
};

function Signup({forwardedSignupState = SignupState.DEFAULT}) {
  const {authState} = useContext(AuthContext);
  const navigate = useNavigate();
  const [tab, setTab] = useState(TabState.LOGIN);
  const [signupState, setSignupState] = useState(forwardedSignupState);

  useEffect(() => {
    if (authState === AuthState.ORGANIZER || authState === AuthState.SPORTSMAN) {
      navigate(titles.others.cabinet.link);
    }
  });

  useEffect(() => setSignupState(forwardedSignupState), [forwardedSignupState]);

  return (
    <div className='login login__signup login__container jcc-aic wrap'>
      <LoginLayout
        background={info.banner}
        title={info.title}
        hideTitle={signupState === SignupState.DEFAULT}
        main='login__main jcc-aic wrap'
      >
        <Main signupState={signupState} tab={tab} setTab={setTab} />
      </LoginLayout>
    </div>
  );
}

const TitleComponent = ({tab, setTab}) => (
  <h1 className='login__heading'>
    <span className={tab === TabState.LOGIN ? 'bold' : ''} onClick={() => setTab(TabState.LOGIN)}>
      {info.text.enter}
    </span>{' '}
    /{' '}
    <span className={tab === TabState.SIGNUP ? 'bold' : ''} onClick={() => setTab(TabState.SIGNUP)}>
      {info.title}
    </span>
  </h1>
);

const Type = ({tab, setTab}) => (
  <div className='login__type  jcsb-aifs w-full'>
    <TitleComponent tab={tab} setTab={setTab} />

    {tab === TabState.LOGIN ? (
      <>
        <Link to={titles.others.signin.state.sportsman.link} className='login__choose'>
          <h5>{info.text.signin_as}</h5>
          <h1>{info.text.sportsman}</h1>
        </Link>
        <Link to={titles.others.signin.state.organizer.link} className='login__choose'>
          <h5>{info.text.signin_as}</h5>
          <h1>{info.text.organizer}</h1>
        </Link>
      </>
    ) : (
      <>
        <Link to={titles.others.signup.state.sportsman.link} className='login__choose'>
          <h5>{info.text.register_as}</h5>
          <h1>{info.text.sportsman}</h1>
        </Link>
        <Link to={titles.others.signup.state.organizer.link} className='login__choose'>
          <h5>{info.text.register_as}</h5>
          <h1>{info.text.organizer}</h1>
        </Link>
      </>
    )}
  </div>
);

function Form({isOrganizer, setShowPopup}) {
  const [data, setData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    await API.POST(isOrganizer ? ENDPOINT.organizer : ENDPOINT.participant, query, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setData({});

          setShowPopup(true);
        }
      })
      .catch((error) => {
        setData(error?.response?.data);
      });

    setIsDisabled(false);
  };

  return (
    <form className='login__form jcsb-aifs' onSubmit={handleSubmit}>
      <div className='login__form-left'>
        <Input
          text={info.text.email + '*'}
          type='email'
          name='email'
          required
          error={data?.email}
        />

        <Input
          text={info.text.password + '*'}
          type='password'
          name='password'
          required
          error={data?.password}
        />

        {isOrganizer && (
          <>
            <Input
              text={info.text.repeat + '*'}
              type='password'
              name='password2'
              required
              error={data?.password2}
            />

            <Input
              text={info.text.name + '*'}
              type='text'
              name='name'
              required
              error={data?.name}
            />

            <Input
              text={info.text.logo + '*'}
              type='file'
              name='logo'
              required
              error={data?.logo}
            />
          </>
        )}

        {!isOrganizer && (
          <>
            <Input
              text={info.text.first_name + '*'}
              type='text'
              name='first_name'
              required
              error={data?.first_name}
            />

            <Input
              text={info.text.middle_name + '*'}
              type='text'
              name='middle_name'
              required
              error={data?.middle_name}
            />

            <Input
              text={info.text.sex + '*'}
              inputType={INPUT_TYPE.SELECT}
              name='sex'
              required
              error={data?.sex}
            >
              {sex.map((value, index) => (
                <option value={value.value} key={index}>
                  {value.title}
                </option>
              ))}
            </Input>

            <Input
              text={info.text.residence_region + '*'}
              inputType={INPUT_TYPE.SELECT}
              name='residence_region'
              required
              error={data?.residence_region}
            >
              {regions.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Input>
          </>
        )}

        <Input
          textAfterInput={<>{info.text.agree.title + ' ' + info.text.agree.personal}</>}
          type='checkbox'
          name='agreement'
          required
        />

        <button className='inactive' type='submit' disabled={isDisabled}>
          {info.text.register}
        </button>
      </div>
      <div className='login__form-right'>
        <Input text={info.text.number + '*'} type='tel' name='phone' required error={data?.phone} />

        {isOrganizer && (
          <>
            <Input text={info.text.url + '*'} type='text' name='url' required error={data?.url} />

            <Input
              text={info.text.contact_name + '*'}
              type='text'
              name='contact_name'
              required
              error={data?.contact_name}
            />

            <Input
              text={info.text.contact_name_job + '*'}
              type='text'
              name='contact_name_job'
              required
              error={data?.contact_name_job}
            />
          </>
        )}

        {!isOrganizer && (
          <>
            <Input
              text={info.text.repeat + '*'}
              type='password'
              name='password2'
              required
              error={data?.password2}
            />

            <Input
              text={info.text.last_name + '*'}
              type='text'
              name='last_name'
              required
              error={data?.last_name}
            />

            <Input
              text={info.text.birth_day + '*'}
              type='date'
              name='birth_day'
              required
              error={data?.birth_day}
            />

            <Input
              text={info.text.tshirt_size + '*'}
              inputType={INPUT_TYPE.SELECT}
              name='tshirt_size'
              required
              error={data?.tshirt_size}
            >
              {tshirt_size.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Input>

            <Input
              text={info.text.living_region + '*'}
              inputType={INPUT_TYPE.SELECT}
              name='living_region'
              required
              error={data?.living_region}
            >
              {regions.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </Input>
          </>
        )}

        <label className='jcfs-aic login__form-right--required'>{info.text.required}</label>

        <h5>
          {info.text.registered}{' '}
          <Link
            to={titles.others.signin.link}
            state={isOrganizer ? AuthState.ORGANIZER : AuthState.SPORTSMAN}
          >
            {info.text.signin}
          </Link>
        </h5>
      </div>
    </form>
  );
}

function Main({signupState, tab, setTab}) {
  const [showPopup, setShowPopup] = useState(false);

  const isOrganizer = signupState === SignupState.ORGANIZER;

  return (
    <div className='login__holder'>
      {signupState === SignupState.DEFAULT ? (
        <Type tab={tab} setTab={setTab} />
      ) : (
        <Form isOrganizer={isOrganizer} setShowPopup={setShowPopup} />
      )}

      {showPopup && (
        <SignupPopup setter={setShowPopup} isOrganizer={isOrganizer} info={info.popup} />
      )}
    </div>
  );
}

export default Signup;
