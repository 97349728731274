import {BannerLayout} from '../components/layout';

import info from '../../info/ru/news.json';
import titles from '../../info/titles.json';
import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT, {MANAGER_ORIGIN} from '../../info/endpoints';
import {externalAPI} from './store';
import Input from '../components/form/input';
import {CabinetPopup} from '../components/popup';
import {Link} from 'react-router-dom';

function News() {
  return (
    <div className='news news__container jcc-aic wrap'>
      <BannerLayout background={info.banner} title={info.title}>
        <Main />
      </BannerLayout>
    </div>
  );
}

function Main() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    API.GET(ENDPOINT.manager.news, externalAPI).then((response) => {
      setNewsData(response?.data);
    });
  }, []);

  return (
    <div className='news__main'>
      <div className='news__wrapper cards jcfs-aifs wrap w-full'>
        {newsData?.map((news) => (
          <div
            className='cards__card column jcfe-aifs'
            key={news?.public_id}
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8)), url(${
                MANAGER_ORIGIN + news?.banners?.preview
              }`
            }}
          >
            <div className='cards__card-title'>{news?.headline}</div>

            <Link
              className='cards__card-button'
              to={titles.titles.news.link + `/${news?.public_id}`}
            >
              <p>{info.news.read_more}</p>
            </Link>
          </div>
        ))}
      </div>

      <Subscription />
    </div>
  );
}

export const Subscription = () => {
  const [errorData, setErrorData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    await API.PUT(ENDPOINT.news_subscription, {email: query?.email}, externalAPI)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setErrorData({});
          setShowPopup(true);
        }
      })
      .catch((error) => {
        setErrorData(error?.response?.data);
      });

    setIsDisabled(false);
  };

  return (
    <div className='news__subscription'>
      {showPopup && <CabinetPopup info={info.popup} setter={setShowPopup} />}

      <div className='advert jcsb-aifs'>
        <div className='advert__text jcfs-aifs'>
          <h3>{info.subsciption.subscribe}</h3>
          <p>{info.subsciption.text}</p>
        </div>

        <form className='advert__text' onSubmit={handleSubmit}>
          <Input
            text={info.subsciption.email + ':'}
            name='email'
            type='email'
            error={errorData?.email}
            required
          />

          <Input
            textAfterInput={<>{info.subsciption.agreement + ' ' + info.subsciption.data}</>}
            type='checkbox'
            name='agreement'
            required
          />

          <button className='inactive' type='submit' disabled={isDisabled}>
            {info.subsciption.action}
          </button>
        </form>
      </div>
    </div>
  );
};

export default News;
