import {useState} from 'react';
import {INTERNAL_ORIGIN} from '../../info/endpoints';
import info from '../../info/ru/protocols.json';
import styles from './cards.module.scss';

export const PREVIEW = {
  excel: '/media/federation/excel.svg',
  pdf: '/media/federation/pdf.svg',
  word: '/media/federation/word.svg',
  folder: '/media/federation/folder.svg'
};

const defaultPreview = '/media/federation/doc2.png';

const Cards = ({json, handleClick = null}) => {
  const [isClickText, setIsClickText] = useState(false);

  const handleClickText = (e) => {
    e.preventDefault();
    setIsClickText(true);
  };

  return (
    <div className='cards jcfs-aifs wrap w-full'>
      {json?.length > 0
        ? json?.map((card, index) => (
            <div className='cards__card column jcsb-aifs' key={index}>
              <div className={styles.ad}>
                {isClickText && (
                  <button
                    onClick={() => setIsClickText(false)}
                    className={styles.popupCloseBtn}
                    type='button'
                    aria-label='Закрыть'
                  >
                    <span className='visually-hidden'></span>
                  </button>
                )}

                {isClickText && (
                  <div className={styles.reklama}>
                    <div className={styles.items}>{card?.reklama?.name}</div>
                    <div className={styles.items}>{card?.reklama?.inn}</div>
                    <div className={styles.items}>{card?.reklama?.gov_id}</div>
                  </div>
                )}
                <div className={`${isClickText && styles.wrapper}`}>
                  <div className='cards__card-logo w-full jcc-aic'>
                    {card?.reklama && !isClickText && (
                      <span onClick={handleClickText} className={styles.text}>
                        Реклама
                      </span>
                    )}
                    <img
                      src={
                        `${INTERNAL_ORIGIN}${card?.picture_main}` ||
                        PREVIEW[card?.type] ||
                        defaultPreview
                      }
                      className={PREVIEW[card?.type] ? 'cards__card-logo--document' : ''}
                      alt={card?.title}
                    />
                  </div>
                </div>
              </div>
              <div className='cards__card-title'>{card?.subtitle}</div>

              {card?.date ? (
                <p className='cards__card-subtitle'>{`${info.cards.date}: ${card.date}`}</p>
              ) : null}

              {card?.section ? (
                <p className='cards__card-subtitle'>{`${info.cards.section}: ${card.section}`}</p>
              ) : null}

              <div className='cards__card-button'>
                {card?.link ? (
                  <a href={card?.link} target='_blank' rel='noreferrer'>
                    На сайт партнера
                  </a>
                ) : (
                  <p onClick={() => handleClick(card)}>{json?.button}</p>
                )}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default Cards;
