import {useCallback, useEffect, useState} from 'react';

function Pagination({items = [], category = null, setVisibleIndexes, max, isVisible = false}) {
  const [currentPage, setCurrentPage] = useState(0);
  const pages = Math.ceil(items.length / max);
  const result = [...new Array(pages)].map((_, index) => index + 1);

  const handleClick = useCallback(
    (page) => {
      setCurrentPage(page);
      setVisibleIndexes({
        start: Math.min(max * page, items.length - 1),
        end: Math.min(max * page + max - 1, items.length - 1)
      });

      document?.getElementById('main')?.scrollIntoView();
    },
    [items.length, max, setVisibleIndexes]
  );

  useEffect(() => {
    handleClick(0);
  }, [category, handleClick]);

  return (
    <>
      {isVisible ? (
        <div className='pagination w-full jcc-aic'>
          <div className='pagination__holder jcc-aic wrap'>
            <div className='pagination__action jcsb-aic'>
              <div onClick={() => handleClick(0)} className='pagination__first pagination__move'>
                {'«'}
              </div>
              <div
                onClick={() => handleClick(Math.max(currentPage - 1, 0))}
                className='pagination__prev pagination__move'
              >
                {'‹'}
              </div>
              <div className='pagination__pages jcsb-aic'>
                {result.map((page, index) => {
                  let current = currentPage === index;

                  return (
                    <div
                      className={
                        current ? 'pagination__page pagination__current' : 'pagination__page'
                      }
                      key={index}
                      onClick={() => handleClick(index)}
                    >
                      {page}
                    </div>
                  );
                })}
              </div>
              <div
                onClick={() => handleClick(Math.min(currentPage + 1, pages - 1))}
                className='pagination__next pagination__move'
              >
                {'›'}
              </div>
              <div
                onClick={() => handleClick(pages - 1)}
                className='pagination__last pagination__move'
              >
                {'»'}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Pagination;
