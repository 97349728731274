import {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import info from '../../../info/ru/cabinet.json';
import titles from '../../../info/titles.json';
import Input, {INPUT_TYPE} from '../../components/form/input';
import EventContext from './eventContext';

const EVENT_TYPE = {
  ACTIVE: 'active_events',
  MODERATING: 'moderating_events',
  ARCHIVE: 'archive_events'
};

function Events({events, sectionSetter}) {
  const [eventType, setEventType] = useState(EVENT_TYPE.ACTIVE);

  const handleEventTypeChange = (e) => setEventType(e.target.value);

  return (
    <>
      <h2 className='cabinet__heading'>{info.event_list.heading}</h2>

      <div className='cabinet__events jcfs-aifs wrap'>
        <div className='cabinet__qual form'>
          <div className='login__form jcsb-aifs wrap'>
            <Input
              text={info.event_list.event_type}
              inputType={INPUT_TYPE.SELECT}
              onChange={handleEventTypeChange}
            >
              <option value={EVENT_TYPE.ACTIVE}>{info.event_list.event_active}</option>
              <option value={EVENT_TYPE.MODERATING}>{info.event_list.event_moderating}</option>
              <option value={EVENT_TYPE.ARCHIVE}>{info.event_list.event_archive}</option>
            </Input>
          </div>
        </div>

        {events?.[eventType]?.map((event, index) => (
          <EventsEvent event={event} sectionSetter={sectionSetter} key={index} />
        ))}
      </div>
    </>
  );
}

function EventsEvent({event}) {
  const {setEventState} = useContext(EventContext);

  const navigate = useNavigate();

  const handleChangeClick = () => {
    setEventState(event?.raw_object).then(() => {
      navigate(titles.others.cabinet.section.event_change.link);
    });
  };

  return (
    <div className='advert calendar__event column jcsb-aic'>
      <div className='calendar__event-header'>
        <div className='calendar__event-header--top jcsb-aic'>
          <h2 className='inline'>{event?.date}</h2>
          <div className='calendar__event-header--logo'>
            {event?.logo ? <img src={event?.logo} alt='event' /> : null}
          </div>
        </div>
        <div className='calendar__event-header--text'>
          <h5>{event?.description}</h5>
        </div>
      </div>

      <div className='calendar__event-footer'>
        <h2>{event?.title}</h2>
        <h5>{event?.subtitle}</h5>
        <h5 className='calendar__event-link'>
          <a href={event?.link} target='_blank' rel='noopener noreferrer'>
            {event?.button}
          </a>
        </h5>
      </div>

      <div className='calendar__event-status'>
        <div className='calendar__event-status--data'>
          {event?.status ? (
            <>
              <h5 className='calendar__event-status--status'>{info.status}:</h5>
              <h5>{event?.status}</h5>
            </>
          ) : null}
        </div>

        {event?.change_url || event?.cancel_url ? (
          <h5 className='calendar__event-changes calendar__event-link' onClick={handleChangeClick}>
            {info.changes}
          </h5>
        ) : null}
      </div>
    </div>
  );
}

export default Events;
