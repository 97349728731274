import {StoreLayout} from '../components/layout';
import info from '../../info/ru/cart.json';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CartPopup} from '../components/popup';
import Input, {INPUT_TYPE} from '../components/form/input';
import CartContext from './cartContext';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';
import {externalAPI} from './store';
import HiddenForm from '../components/hiddenForm';

const onlyNumbersOrEmptyRegex = /^(\s*|\d+)$/;

function Cart() {
  return (
    <div className='cart cart__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.title} main='cart__main'>
        <Main />
      </StoreLayout>
    </div>
  );
}

function Main() {
  const [showPopup, setShowPopup] = useState(false);
  const {cartState, updateCartState} = useContext(CartContext);
  const [cartItems, setCartItems] = useState(cartState);

  useEffect(() => {
    const uniqueItems = cartState.filter(
      (v, i, a) => a.findIndex((v2) => v2.size.public_id === v.size.public_id) === i
    );

    uniqueItems.forEach((item, index) => {
      const sameItemsCount = cartState.filter(
        (cartItem) => cartItem.size.public_id === item.size.public_id
      ).length;

      uniqueItems[index].count = sameItemsCount;
    });

    setCartItems(uniqueItems);
  }, [cartState]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('success')) {
      setShowPopup(true);

      updateCartState.clear();
    }
  }, [updateCartState]);

  return (
    <>
      {showPopup && <CartPopup setter={setShowPopup} info={info.popup} />}

      <div className='cart__holder jcsb-aifs'>
        <div className='cart__products'>
          {cartItems
            ?.sort((prev, next) => (prev.size.public_id > next.size.public_id ? 1 : -1))
            .map((item, index) => (
              <Product item={item} key={index} />
            ))}
        </div>

        <Form goods={cartItems} />
      </div>
    </>
  );
}

function Form({goods = []}) {
  const [regions, setRegions] = useState(null);
  const [cities, setCities] = useState(null);
  const [currentRegion, setCurrentRegion] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentPhone, setCurrentPhone] = useState('');
  const [delivery, setDelivery] = useState(null);
  const [errorData, setErrorData] = useState({});
  const [IPCity, setIPCity] = useState(null);
  const [requestPayment, setRequestPayment] = useState(null);

  const formButton = useRef();
  const paymentFormKeys = requestPayment?.form && Object.keys(requestPayment.form);

  useEffect(() => {
    API.GET(ENDPOINT.league.regions, externalAPI).then((regionsResponse) => {
      setRegions(regionsResponse.data.values);

      API.GET(ENDPOINT.league.location, externalAPI).then((locationResponse) => {
        // if user located in RU region
        if (
          regionsResponse.data.values
            .map((region) => region.id)
            .includes(locationResponse.data.info?.region?.id)
        ) {
          setIPCity(locationResponse.data.info?.city?.id);
          setCurrentRegion(locationResponse.data.info?.region?.id);
        } else {
          setCurrentRegion(regionsResponse.data.values?.[0].id);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (formButton && requestPayment) {
      formButton.current.click();
    }
  }, [formButton, requestPayment]);

  useEffect(() => {
    if (currentRegion) {
      API.GET(ENDPOINT.league.cities + currentRegion, externalAPI).then((response) => {
        setCities(response.data.values);

        if (IPCity) {
          setCurrentCity(IPCity);
          setIPCity(null);
        } else {
          setCurrentCity(response.data.values?.[0].id);
        }
      });
    }
    // this should be triggered only on currentRegion update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    if (currentCity) {
      API.POST(
        ENDPOINT.league.delivery_price,
        {
          city: {id: currentCity},
          goods: goods.map((item) => ({public_id: item.size.public_id, count: item.count}))
        },
        externalAPI
      ).then((response) => {
        setDelivery(response.data?.delivery);
      });
    }
  }, [currentCity, goods]);

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const request = {email: event.target.email.value};
      const deliveryObject = {};

      for (var pair of new FormData(event.target).entries()) {
        if (pair[0] === 'city') {
          deliveryObject['location_id'] = pair[1];
        } else if (pair[0] !== 'email' && pair[0] !== 'region') {
          deliveryObject[pair[0]] = pair[1];
        }
      }

      request.delivery = deliveryObject;
      request.certificates = [];
      request.tickets = [];
      request.project = 'ocr';

      request.shop = goods.map((item) => ({
        count: item.count,
        promocode: null,
        proportion: {public_id: item?.object?.proportions[0]?.public_id},
        product: {public_id: item.art}
      }));
      API.POST(ENDPOINT.league.payment, request, externalAPI)
        .then((response) => {
          setRequestPayment(response.data);
          // window.location.replace(response.data.redirect_url);
        })
        .catch((error) => {
          setErrorData(error?.response?.data?.delivery);
        });
    },
    [goods]
  );

  return (
    <>
      <form className='cart__order advert' onSubmit={handleFormSubmit}>
        <div className='cart__order-header'>
          <h4>{info.text.form.title}</h4>
          <h6>{info.text.form.info}</h6>
        </div>

        <div className='cart__order-body'>
          <h4 className='cart__order-body--title-first'>{info.text.form.data}</h4>

          <Input
            text={info.text.form.firstname}
            error={errorData?.first_name}
            name='first_name'
            type='text'
            required
          />
          <Input
            text={info.text.form.lastname}
            error={errorData?.last_name}
            name='last_name'
            type='text'
            required
          />
          <Input
            text={info.text.form.email}
            error={errorData?.email}
            name='email'
            type='email'
            required
          />
          <Input
            text={info.text.form.number}
            fixedSymbol='+'
            value={currentPhone}
            onChange={(e) =>
              setCurrentPhone((prevPhone) =>
                e.target.value.match(onlyNumbersOrEmptyRegex) ? e.target.value : prevPhone
              )
            }
            error={errorData?.phone}
            name='phone'
            type='tel'
            required
          />

          <h4 className='cart__order-body--title-second'>{info.text.form.delivery.title}</h4>

          <Input
            value={currentRegion || ''}
            onChange={(e) => setCurrentRegion(e.target.value)}
            text={info.text.form.delivery.region}
            inputType={INPUT_TYPE.SELECT}
            name='region'
            required
          >
            {regions?.map((region) => (
              <option value={region.id} key={region.id}>
                {region.name_ru}
              </option>
            ))}
          </Input>

          <Input
            value={currentCity || ''}
            onChange={(e) => setCurrentCity(e.target.value)}
            text={info.text.form.delivery.city}
            inputType={INPUT_TYPE.SELECT}
            name='city'
            required
          >
            {cities?.map((city) => (
              <option value={city.id} key={city.id}>
                {city.name_ru}
              </option>
            ))}
          </Input>

          <Input text={info.text.form.delivery.address} name='address' type='text' required />
        </div>
        <div className='cart__order-footer'>
          {delivery?.cost ? (
            <div className='cart__order-footer--delivery jcsb-aic'>
              <h5>{info.text.form.result.delivery}:</h5>
              <h5>{delivery?.cost} ₽</h5>
            </div>
          ) : null}
          <div className='cart__order-footer--result jcsb-aic'>
            <h4>{info.text.form.result.result}:</h4>
            <h2>
              {(delivery?.cost || 0) +
                goods.reduce((prev, current) => prev + current.count * current.price, 0)}{' '}
              ₽
            </h2>
          </div>

          <Input
            labelClass={'cart__order-footer--letter'}
            type='text'
            name='comment'
            text={info.text.form.comment}
          />

          <button className='inactive w-full' type='submit'>
            {info.text.form.pay}
          </button>
          <h6>{info.text.form.agree}</h6>
        </div>
      </form>
      <HiddenForm
        requestPayment={requestPayment}
        paymentFormKeys={paymentFormKeys}
        formButton={formButton}
      />
    </>
  );
}

function Product({item}) {
  const {updateCartState} = useContext(CartContext);

  return (
    <div className='cart__product'>
      <div className='cart__product-data jcsb-aifs'>
        <div className='cart__product-photo'>
          <img src={item?.img} alt='product' />
        </div>
        <div className='cart__product-info'>
          <div className='cart__product-info--title'>
            <h5>{info.text.product}:</h5>
            <h4>{item?.title}</h4>
          </div>

          <div className='cart__product-info--data jcfs-aifs'>
            {item?.size?.name !== '' && (
              <div className='cart__product-info--size'>
                <h5>{info.text.size}:</h5>

                <div className='flex'>
                  <h4>{item?.size?.name}</h4>
                </div>
              </div>
            )}
            <div className='cart__product-info--art'>
              <h5>{info.text.art}:</h5>
              <h4>{item?.size?.public_id}</h4>
            </div>
          </div>

          <div className='cart__product-info--action jcsb-aife'>
            <div className='cart__product-info--count flex'>
              <h5>{info.text.count}:</h5>
              <h4 className='jcsb-aic'>
                <span
                  onClick={() => updateCartState.addItem(item)}
                  className='cart__product-info--count-action'
                >
                  +
                </span>{' '}
                <span className='cart__product-info--count-text'>
                  {item?.count} {info.text.pieces}{' '}
                </span>
                <span
                  onClick={() => updateCartState.removeItem(item)}
                  className='cart__product-info--count-action'
                >
                  -
                </span>
              </h4>
            </div>

            <div className='cart__product-info--price'>
              <h5>{info.text.price}:</h5>
              <h2>{item?.price * item?.count} ₽</h2>
            </div>
          </div>
        </div>
      </div>

      <div className='cart__product-code jcsb-aic'>
        <h5 className='cart__product-code--title'>{info.text.have_promo}</h5>

        <input type='text' />

        <h5 className='cart__product-code--confirm'>{info.text.accept}</h5>

        <h6 onClick={() => updateCartState.clearItem(item)} className='cart__product-code--remove'>
          {info.text.remove} Х
        </h6>
      </div>
    </div>
  );
}

export default Cart;
