import {LoginLayout} from '../../components/layout';
import info from '../../../info/ru/signin.json';
import titles from '../../../info/titles.json';
import {SigninPopup} from '../../components/popup';
import {useNavigate} from 'react-router-dom';
import {useState, useContext} from 'react';
import AuthContext, {AuthState} from '../../functions/auth';
import ENDPOINT from '../../../info/endpoints';
import API from '../../functions/api';
import Input from '../../components/form/input';
import device from '../../functions/device';
import Cookies from 'js-cookie';
import {NAVIGATE_TO_LICENSES_COOKIE} from '../contribution';

function Signin({forwardedAuthState = AuthState.DEFAULT}) {
  return (
    <div className='login login__signin login__container jcc-aic wrap'>
      <LoginLayout background={info.banner} title={info.title} main='login__main jcc-aic wrap'>
        <div className='login__holder'>
          <Form authState={forwardedAuthState} />
        </div>
      </LoginLayout>
    </div>
  );
}

function Form({authState}) {
  const [showPopup, setShowPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();
  const {setAuthState} = useContext(AuthContext);

  const isOrganizer = authState === AuthState.ORGANIZER;
  const endpoint = isOrganizer ? ENDPOINT.organizer_login : ENDPOINT.participant_login;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    await API.POST(endpoint, query)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setData({});

          setAuthState(isOrganizer ? AuthState.ORGANIZER : AuthState.SPORTSMAN).then(() => {
            if (!isOrganizer && Cookies.get(NAVIGATE_TO_LICENSES_COOKIE) === 'true') {
              Cookies.remove(NAVIGATE_TO_LICENSES_COOKIE);

              navigate(titles.others.cabinet.section.licenses.link);
            } else {
              navigate(titles.others.cabinet.link);
            }
          });
        }
      })
      .catch((error) => {
        setData(error?.response?.data);
      });

    setIsDisabled(false);
  };

  return (
    <>
      {showPopup && (
        <SigninPopup setter={setShowPopup} handler={() => navigate('/')} info={info.popup} />
      )}

      <form onSubmit={handleSubmit} className='login__form jcsb-aifs'>
        <div className='login__form-left jcfs-aic wrap'>
          <Input
            text={info.text.email + ':'}
            type='email'
            name='username'
            required
            error={data?.username}
          />

          {device('mobile') && (
            <Input
              text={info.text.password + ':'}
              type='password'
              name='password'
              required
              error={data?.password}
            />
          )}

          <button className='inactive' type='submit' disabled={isDisabled}>
            {info.text.signin}
          </button>

          <h5 className='inline' onClick={() => setShowPopup(true)}>
            {info.text.forgot}
          </h5>
        </div>

        {device('desktop') && (
          <div className='login__form-right jcc-aifs'>
            <Input
              text={info.text.password + ':'}
              type='password'
              name='password'
              required
              error={data?.password}
            />
          </div>
        )}
      </form>
    </>
  );
}

export default Signin;
