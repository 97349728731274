import {LoginLayout} from '../../components/layout';
import info from '../../../info/ru/passwordReset.json';
import {PasswordResetPopup} from '../../components/popup';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import ENDPOINT from '../../../info/endpoints';
import API from '../../functions/api';
import Input from '../../components/form/input';

function PasswordReset() {
  return (
    <div className='login login__signin login__container jcc-aic wrap'>
      <LoginLayout background={info.banner} title={info.title} main='login__main jcc-aic wrap'>
        <div className='login__holder'>
          <Form />
        </div>
      </LoginLayout>
    </div>
  );
}

function Form() {
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const {token} = useParams();

  useEffect(() => {
    async function checkValidity() {
      await API.POST(ENDPOINT.password_validate, {token})
        .then(() => {})
        .catch(() => {
          setIsSuccess(false);
          setShowPopup(true);
        });
    }

    checkValidity();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    if (query.password !== query.password2) {
      setError(['Пароли не совпадают']);
      setIsDisabled(false);

      return;
    }

    delete query.password2;

    query.token = token;

    await API.POST(ENDPOINT.password_confirm, query)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setIsSuccess(true);
          setShowPopup(true);
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.password || []);
      });

    setIsDisabled(false);
  };

  return (
    <>
      {showPopup && (
        <PasswordResetPopup handler={() => navigate('/')} info={info.popup} isSuccess={isSuccess} />
      )}

      <form onSubmit={handleSubmit} className='login__form password__reset flex column'>
        <div className='login__form-left jcsb-aifs'>
          <Input
            text={info.text.password + ':'}
            type='password'
            name='password'
            required
            error={error}
          />

          <Input text={info.text.password2 + ':'} type='password' name='password2' required />
        </div>

        <button className='inactive' type='submit' disabled={isDisabled}>
          {info.text.reset}
        </button>
      </form>
    </>
  );
}

export default PasswordReset;
