import {StoreLayout} from '../../components/layout';
import info from '../../../info/ru/return.json';

function Return() {
  return (
    <div className='article article__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.title} main='article__main jcc-aic wrap'>
        <Main />
      </StoreLayout>
    </div>
  );
}

function Main() {
  return (
    <div className='article__holder jcfs-aifs wrap'>
      <h2>Правила обмена и возврата товара:</h2>
      <p>
        Продавец гарантирует, что покупатель в течение 14 дней с момента приобретения товара может
        отказаться от товара, обменять товар на другой товар, идентичный по стоимости или на иной с
        доплатой или возвратом разницы в цене, а также потребовать замену товара или возврат
        денежных средств у продавца, если:
      </p>
      <ul>
        <li>
          товар не поступал в эксплуатацию и имеет товарный вид, находится в упаковке со всеми
          ярлыками, а также есть документы на приобретение товара;
        </li>
        <li>
          товар не входит в перечень продуктов качества, не подлежащих возврату и обмену (именные
          позиции);
        </li>
        <li>покупатель заполнил заявление на обмен товара или возврат денежных средств.</li>
      </ul>
      <div>
        <p>Срок возврата денежных средств до 30 календарных дней.</p>

        <p>Контактный телефон: 8 499 322 89 70</p>

        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          {' '}
          <a style={{textDecoration: 'underline'}} href='/documents/change.doc'>
            Заявление на обмен
          </a>
          <a style={{textDecoration: 'underline'}} href='/documents/return_doc.doc'>
            Заявление на возврат
          </a>
        </div>
      </div>
    </div>
  );
}

export default Return;
