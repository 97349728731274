import {BannerLayout} from '../components/layout';
import Cards from '../components/cards';
import {useEffect, useState} from 'react';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';

function AccreditedRaces() {
  const [data, setData] = useState({});

  useEffect(() => {
    API.GET(ENDPOINT.accredited_races).then((response) => setData(response.data));
  }, []);

  return (
    <div className='accreditedRaces accreditedRaces__container jcc-aic wrap'>
      <BannerLayout background={data?.banner} title={data?.title}>
        <Main data={data} />
      </BannerLayout>
    </div>
  );
}

const Main = ({data}) => (
  <div className='accreditedRaces__main'>
    <p className='accreditedRaces__text'>{data?.after_banner}</p>
    <Cards json={data?.cards} />
  </div>
);

export default AccreditedRaces;
