import {BannerLayout} from '../../components/layout';
import {useState, useContext, useEffect, useCallback} from 'react';

import info from '../../../info/ru/cabinet.json';
import titles from '../../../info/titles.json';
import API from '../../functions/api';
import ENDPOINT from '../../../info/endpoints';
import {Link, useNavigate} from 'react-router-dom';
import AuthContext, {AuthState} from '../../functions/auth';

import {ReactComponent as AddfileIcon} from '../../../icons/addfile.svg';
import {ReactComponent as BlankIcon} from '../../../icons/blankfile.svg';
import {ReactComponent as DocIcon} from '../../../icons/doc.svg';
import {ReactComponent as NewsletterIcon} from '../../../icons/newsletter.svg';
import {ReactComponent as ProfileIcon} from '../../../icons/profile.svg';
import {ReactComponent as CartIcon} from '../../../icons/cart.svg';

import Settings from './settings';
import Qual from './qual';
import Event from './event';
import Events from './events';
import Activity from './activity';
import History from './history';
import EventContext from './eventContext';
import Licenses from './licenses';

export const SECTION = {
  SETTINGS: 'settings',
  LICENSES: 'licenses',
  ORDERS: 'orders',
  QUAL: 'qual',
  HISTORY: 'history',
  EVENT_CREATE: 'event_create',
  EVENT_CHANGE: 'event_change',
  EVENT_LIST: 'event_list',
  ACTIVITY: 'activity'
};

function Cabinet({forwardSection}) {
  const [section, updateSection] = useState(SECTION.SETTINGS);
  const {setEventState} = useContext(EventContext);

  const setSection = useCallback(
    (newSection) => {
      if (section !== newSection) {
        updateSection(newSection);

        if (newSection !== SECTION.EVENT_CHANGE) {
          setEventState(null);
        }
      }
    },
    [setEventState, section]
  );

  useEffect(() => {
    if (forwardSection) {
      setSection(forwardSection);
    }
  }, [setSection, forwardSection]);

  return (
    <div className='cabinet cabinet__container jcc-aic wrap'>
      <BannerLayout background={info.banner} title={info.title}>
        <Main section={section} sectionSetter={setSection} />
      </BannerLayout>
    </div>
  );
}

const isSection = (currentSection, expectedSection) =>
  expectedSection === currentSection ? 'cabinet__page--current' : '';

function Main({section}) {
  const {authState, setAuthState} = useContext(AuthContext);
  const [infoData, setInfoData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();

  const isOrganizer = authState === AuthState.ORGANIZER;
  const infoEndpoint = isOrganizer ? ENDPOINT.organizer_me : ENDPOINT.participant_me;

  const handleLogout = async () => {
    setIsDisabled(true);

    await API.POST(ENDPOINT.logout)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setAuthState(AuthState.DEFAULT).then(() => {
            navigate('/');
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setIsDisabled(false);
  };

  useEffect(() => {
    API.GET(infoEndpoint)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setInfoData(response?.data);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          setAuthState(AuthState.DEFAULT).then(() => {
            navigate(titles.others.signup.link);
          });
        }
      });
  }, [infoEndpoint, navigate, setAuthState, setInfoData]);

  return (
    <div className='cabinet__main jcsb-aifs'>
      <div className='cabinet__left'>
        <div className='cabinet__pages advert'>
          <Link to={titles.others.cabinet.section.settings.link}>
            <h5 className={`cabinet__page jcfs-aic ${isSection(section, SECTION.SETTINGS)}`}>
              <ProfileIcon />
              {info.sections.settings.title}
            </h5>
          </Link>

          {!isOrganizer && (
            <>
              {/* <Link to={titles.others.cabinet.section.licenses.link}>
                <h5 className={`cabinet__page jcfs-aic ${isSection(section, SECTION.LICENSES)}`}>
                  <DocIcon />
                  {info.sections.licenses.title}
                </h5>
              </Link> */}

              <Link to='#'>
                <h5 className={`cabinet__page jcfs-aic ${isSection(section, SECTION.ORDERS)}`}>
                  <CartIcon />
                  {info.sections.orders.title}
                </h5>
              </Link>
            </>
          )}

          {isOrganizer && (
            <>
              <Link to={titles.others.cabinet.section.qual.link}>
                <h5 className={`cabinet__page jcfs-aic ${isSection(section, SECTION.QUAL)}`}>
                  <DocIcon />
                  {info.sections.qual.title}
                </h5>
              </Link>

              <Link to={titles.others.cabinet.section.event_create.link}>
                <h5
                  className={`cabinet__page jcfs-aic ${isSection(section, SECTION.EVENT_CREATE)}`}
                >
                  <AddfileIcon />
                  {info.sections.event_create.title}
                </h5>
              </Link>

              <EventChange section={section} />

              <Link to={titles.others.cabinet.section.event_list.link}>
                <h5 className={`cabinet__page jcfs-aic ${isSection(section, SECTION.EVENT_LIST)}`}>
                  <NewsletterIcon />
                  {info.sections.event_list.title}
                </h5>
              </Link>
            </>
          )}
        </div>

        <button className='w-full' onClick={handleLogout} disabled={isDisabled}>
          {info.leave}
        </button>
      </div>

      <div className='cabinet__right'>
        {section === SECTION.SETTINGS && (
          <Settings data={{...infoData, document_sections: null, events: null}} />
        )}
        {section === SECTION.LICENSES && <Licenses data={infoData?.card} />}
        {section === SECTION.QUAL && (
          <Qual
            data={{
              events: infoData?.events,
              document_sections: infoData?.document_sections,
              protocols: infoData?.protocols
            }}
          />
        )}
        {(section === SECTION.EVENT_CREATE || section === SECTION.EVENT_CHANGE) && (
          <Event section={section} />
        )}
        {section === SECTION.EVENT_LIST && <Events events={infoData?.events} />}
        {section === SECTION.ACTIVITY && <Activity />}
        {section === SECTION.HISTORY && <History />}
      </div>
    </div>
  );
}

function EventChange({section}) {
  const {eventState} = useContext(EventContext);

  return eventState ? (
    <h5 className={`cabinet__page jcfs-aic ${isSection(section, SECTION.EVENT_CHANGE)}`}>
      <BlankIcon />
      {info.sections.event_change.title}
    </h5>
  ) : null;
}

export default Cabinet;
