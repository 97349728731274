import Header from '../components/header';
import Footer from '../components/footer';
import Banner from '../components/banner';
import {Link} from 'react-router-dom';
import titles from '../../info/titles.json';

function HomeLayout({children}) {
  return (
    <>
      <Header />

      {children}

      <Footer />
    </>
  );
}

function BannerLayout({children, background, title}) {
  return (
    <>
      <Header />
      <Banner background={background} title={title} />

      {children}

      <Footer />
    </>
  );
}

function StoreLayout({children, background, title, main, photo = null}) {
  return (
    <>
      <Header />

      <div className='banner w-full jcc-aic relative'>
        <img src={background} alt={title} />
      </div>

      <div className={main} id={main}>
        <div className='advert advert__store advert__margin-big jcsb-aic'>
          <div className='advert__text column jcsb-aifs'>
            <h5>
              <Link to='/'>Главная</Link> {'>'}{' '}
              <Link to={titles.titles.store.link}>Интернет-магазин</Link> {'>'} {title}
            </h5>
            <h1>{title}</h1>
          </div>

          {photo ? (
            <div className='advert__photo'>
              <img src={photo} alt='banner' />
            </div>
          ) : (
            ''
          )}
        </div>

        {children}
      </div>

      <Footer />
    </>
  );
}

function LoginLayout({children, background, title, hideTitle = false, main}) {
  return (
    <>
      <Header />

      <div className='banner w-full jcc-aic relative'>
        <img src={background} alt={title} />
      </div>

      <div className={main}>
        <div className='advert advert__store advert__margin-big jcsb-aic wrap'>
          <div className='advert__text column jcsb-aifs'>
            <h5>
              Главная {'>'} {title}
            </h5>
            {!hideTitle && <h1>{title}</h1>}
          </div>

          {children}
        </div>
      </div>

      <Footer />
    </>
  );
}

export {HomeLayout, BannerLayout, StoreLayout, LoginLayout};
