import {BannerLayout} from '../components/layout';
import Cards from '../components/cards';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';
import {useEffect, useState} from 'react';

function Doping() {
  const [data, setData] = useState({});

  useEffect(() => {
    API.GET(ENDPOINT.doping).then((response) => setData(response.data));
  }, []);

  return (
    <div className='doping doping__container jcc-aic wrap'>
      <BannerLayout background={data?.banner} title={data?.title}>
        <Main data={data} />
      </BannerLayout>
    </div>
  );
}

function Main({data}) {
  return (
    <div className='doping__main'>
      <Cards json={data?.cards} />
      <div className='advert jcsb-aic'>
        <div className='advert__text'>
          <h3>{data?.card?.text}</h3>

          <a href={data?.card?.link} target='_blank' rel='noopener noreferrer'>
            <button>{data?.card?.button} </button>
          </a>
        </div>
        <div className='advert__photo'>
          <img src='/media/doping/card.png' alt='card' />
        </div>
      </div>
    </div>
  );
}

export default Doping;
