import {StoreLayout} from '../../components/layout';
import info from '../../../info/ru/payment.json';

function Payment() {
  return (
    <div className='article article__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.title} main='article__main jcc-aic wrap'>
        <Main />
      </StoreLayout>
    </div>
  );
}

function Main() {
  return (
    <div className='article__holder jcfs-aifs wrap'>
      <p>
        При оформлении заказа возможен только безналичный расчёт. Чтобы оплатить покупку, вас
        перенаправит на сервер системы UNITELLER, где вы должны ввести номер карты, срок действия,
        имя держателя.
      </p>
      <h4>Вам могут отказать от авторизации в случае:</h4>
      <ul>
        <li>если ваш банк не поддерживает технологию 3D-Secure;</li>
        <li>на карте недостаточно средств для покупки;</li>
        <li>банк не поддерживает услугу платежей в интернете;</li>
        <li>истекло время ожидания ввода данных;</li>
        <li>в данных была допущена ошибка.</li>
      </ul>
      <p>
        В этом случае вы можете повторить авторизацию через 20 минут, воспользоваться другой картой
        или обратиться в свой банк для решения вопроса.
      </p>
      <h4>Гарантия безопасности</h4>
      <p>
        Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных
        индустрии платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной
        системой мониторинга мошеннических операций, а также применением 3D Secure - современной
        технологией безопасности интернет-платежей. Данные Вашей карты вводятся на специальной
        защищенной платежной странице. Передача информации в процессинговую компанию Uniteller
        происходит с применением технологии шифрования TLS. Дальнейшая передача информации
        осуществляется по закрытым банковским каналам, имеющим наивысший уровень надежности.
        Uniteller не передает данные Вашей карты магазину и иным третьим лицам! Если Ваша карта
        поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти
        дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту).
        Для этого Вы будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от
        банка. Как правило, это дополнительный пароль, который отправляется в SMS, карта переменных
        кодов, либо другие способы. Если у Вас возникли вопросы по совершенному платежу, Вы можете
        обратиться в службу технической поддержки процессингового центра Uniteller:{' '}
        <a href='mailto:support@uniteller.ru'>support@uniteller.ru</a> или по телефону{' '}
        <a href='tel:+7 800 707 67 19'>8 800 707 67 19</a>.
      </p>
    </div>
  );
}

export default Payment;
