import React, {useState} from 'react';

const EventContext = React.createContext({});

export const EventProvider = ({children}) => {
  const [eventState, updateEventState] = useState(null);

  const setEventState = async (state) =>
    new Promise((resolve) => {
      updateEventState(state);

      resolve(true);
    });

  return (
    <EventContext.Provider value={{eventState, setEventState}}>{children}</EventContext.Provider>
  );
};

export default EventContext;
