import {BannerLayout} from '../components/layout';
import Cards from '../components/cardsPartners';
import {PartnersPopup} from '../components/popup';
import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';
import info from '../../info/ru/partners.json';
import {externalAPI} from './store';

function Partners() {
  const [data, setData] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    API.GET(ENDPOINT.partnersCards, externalAPI).then((response) =>
      setCards(response.data?.values)
    );
  }, []);

  useEffect(() => {
    API.GET(ENDPOINT.partners).then((response) => setData(response.data));
  }, []);

  return (
    <div className='partners partners__container jcc-aic wrap'>
      <BannerLayout background={data?.banner} title={data?.title}>
        <Main data={data} cards={cards} />
      </BannerLayout>
    </div>
  );
}

function Main({data, cards}) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className='partners__main'>
      {showPopup && <PartnersPopup setter={setShowPopup} info={data?.popup} />}

      <h2 className='partners__heading'>{cards[0]?.subtitle}</h2>
      <Cards json={cards} />

      <div className='advert partners__card'>
        <div className='advert__text'>
          <h3>{data?.card?.title}</h3>
          <p>{info.card.text}</p>
          <button onClick={() => setShowPopup(true)}>
            {data?.card?.button || info.card.button}
          </button>
        </div>
        <div className='advert__photo'>
          <img src='/media/partners/card1.png' alt='card' />
        </div>
      </div>
    </div>
  );
}

export default Partners;
