import {BannerLayout} from '../components/layout';
import Cards from '../components/cards';

import info from '../../info/ru/protocols.json';
import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';

function Protocols() {
  return (
    <div className='protocols protocols__container jcc-aic wrap'>
      <BannerLayout background={info.banner} title={info.title}>
        <Main />
      </BannerLayout>
    </div>
  );
}

const DEEP_LEVEL = {
  SECTIONS: 0,
  DOCUMENTS: 1
};

function Main() {
  const [data, setData] = useState({
    organizers: null,
    sections: null,
    documents: null
  });

  useEffect(() => {
    API.GET(ENDPOINT.organizers).then((response) => {
      setData((data) => ({
        ...data,
        organizers: {
          button: info.cards.protocols,
          cards: response.data?.map((organizer) => ({
            logo: organizer.logo,
            title: organizer.name,
            sections: organizer.sections
          }))
        }
      }));
    });
  }, []);

  const handleOrganizerClick = (organizer) => {
    setData((data) => ({
      ...data,
      sections: {
        button: info.cards.protocols,
        cards: organizer?.sections?.map((section) => ({
          type: 'folder',
          title: section.name,
          documents_url: section.documents_url
        }))
      }
    }));
  };

  const handleSectionClick = (section) => {
    API.GET(section?.documents_url).then((response) => {
      setData((data) => ({
        ...data,
        documents: {button: info.cards.button, cards: response.data}
      }));
    });
  };

  const handleBackClick = (deep) => {
    if (deep === DEEP_LEVEL.SECTIONS) {
      setData((data) => ({...data, sections: null}));
    }

    if (deep === DEEP_LEVEL.DOCUMENTS) {
      setData((data) => ({...data, documents: null}));
    }
  };

  return (
    <div className='protocols__main'>
      {data?.documents || data?.sections ? (
        <div className='protocols__section'>
          <p
            className='protocols__back'
            onClick={() =>
              handleBackClick(data?.documents ? DEEP_LEVEL.DOCUMENTS : DEEP_LEVEL.SECTIONS)
            }
          >
            {info.back}
          </p>

          <Cards
            json={data?.documents || data?.sections}
            handleClick={data?.sections ? handleSectionClick : null}
          />
        </div>
      ) : data?.organizers ? (
        <Cards json={data?.organizers} handleClick={handleOrganizerClick} />
      ) : null}
    </div>
  );
}

export default Protocols;
