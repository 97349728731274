import {BannerLayout} from '../components/layout';
import Cards from '../components/cards';
import {useEffect, useState} from 'react';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';

function QualifyingRaces() {
  const [data, setData] = useState({});

  useEffect(() => {
    API.GET(ENDPOINT.qualifying_races).then((response) => setData(response.data));
  }, []);

  return (
    <div className='qualifyingRaces qualifyingRaces__container jcc-aic wrap'>
      <BannerLayout background={data?.banner} title={data?.title}>
        <Main data={data} />
      </BannerLayout>
    </div>
  );
}

function Main({data}) {
  return (
    <div className='qualifyingRaces__main'>
      <div className='qualifyingRaces__block jcsb-aic w-full'>
        <h4>{data?.after_banner}</h4>
        <div className='qualifyingRaces__sponsors'>
          <img src='/media/qualifyingRaces/sponsors.png' alt='sponsors' />
        </div>
      </div>

      <Cards json={data?.cards} />
    </div>
  );
}

export default QualifyingRaces;
