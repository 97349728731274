import ENDPOINT, {INTERNAL_ORIGIN} from '../../info/endpoints';

const axios = require('axios').default;

const defaultConfig = {
  withCredentials: true
};

const tokenHeader = 'X-CSRFToken';

class api {
  constructor() {
    this.HOST = `${INTERNAL_ORIGIN}/ocr`;
  }

  async getToken() {
    return new Promise((resolve) => {
      API.GET(ENDPOINT.token).then((response) => {
        resolve(response.data);
      });
    });
  }

  async GET(endpoint = null, options = {}) {
    return axios.get(options.absolute ? endpoint : this.HOST + endpoint, {
      ...options,
      ...defaultConfig
    });
  }

  async POST(endpoint = null, data = {}, options = {headers: {}}) {
    if (!options.absolute) options.headers[tokenHeader] = await this.getToken();

    return axios.post(options.absolute ? endpoint : this.HOST + endpoint, data, {
      ...options,
      ...defaultConfig
    });
  }

  async PUT(endpoint = null, data = {}, options = {headers: {}}) {
    if (!options.absolute) options.headers[tokenHeader] = await this.getToken();

    return axios.put(options.absolute ? endpoint : this.HOST + endpoint, data, {
      ...options,
      ...defaultConfig
    });
  }

  async PATCH(endpoint = null, data = {}, options = {headers: {}}) {
    options.headers[tokenHeader] = await this.getToken();

    return axios.patch(this.HOST + endpoint, data, {
      ...options,
      ...defaultConfig
    });
  }

  async DELETE(endpoint = null, options = {headers: {}}) {
    options.headers[tokenHeader] = await this.getToken();

    return axios.delete(this.HOST + endpoint, {
      ...options,
      ...defaultConfig
    });
  }
}

const API = new api();

export default API;
