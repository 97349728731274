import {StoreLayout} from '../../components/layout';
import info from '../../../info/ru/delivery.json';

function Contact() {
  return (
    <div className='article article__container jcc-aic wrap'>
      <StoreLayout background={info.banner} title={info.contact} main='article__main jcc-aic wrap'>
        <Main />
      </StoreLayout>
    </div>
  );
}

function Main() {
  return (
    <div className='article__holder jcfs-aifs wrap'>
      <h2>Контакты для связи:</h2>

      <h4>
        E-mail:{' '}
        <a style={{fontWeight: '400'}} href='mailto:shop@ocrrussia.com'>
          shop@ocrrussia.com
        </a>
      </h4>
      <h4 style={{marginTop: '20px'}}>
        Номер телефона:{' '}
        <a style={{fontWeight: '400'}} href='tel:+7 499 322 89 70'>
          8 499 322 89 70
        </a>
      </h4>
    </div>
  );
}

export default Contact;
