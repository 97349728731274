import info from '../../info/ru/header.json';
import titles from '../../info/titles.json';
import links from '../../info/links.json';
import {Icon} from '@iconify/react';
import cart from '@iconify/icons-eva/shopping-cart-outline';
import user from '@iconify/icons-bx/bx-user-circle';
import tg from '@iconify/icons-bx/bxl-telegram';
import vk from '@iconify/icons-bx/bxl-vk';

import {Link} from 'react-router-dom';
import {useContext, useState} from 'react';

import overflow from '../functions/overflow';
import CartContext from '../pages/cartContext';
import AuthContext, {AuthState} from '../functions/auth';

function Header() {
  const [hamburger, setHamburger] = useState(false);

  function hamburgerState(state) {
    if (state) {
      overflow('auto');
    } else {
      overflow('hidden');
    }

    setHamburger(!state);
  }

  return (
    <header className='header fixed w-full jcsb-aic'>
      <Link className='header__logo' to='/'>
        <img src='/media/logo.svg' alt='logo' />
      </Link>

      <HeaderContent className='header__content' />

      <div className='header__hamburger'>
        <div className='header__hamburger-action' onClick={() => hamburgerState(hamburger)}>
          {hamburger && (
            <div className='header__hamburger-close'>
              <img src='/media/close.svg' alt='hamburger' />
            </div>
          )}
          {!hamburger && (
            <div className='header__hamburger-open'>
              <img src='/media/hamburger.svg' alt='hamburger' />
            </div>
          )}
        </div>
        <div
          className={
            hamburger
              ? `header__hamburger-holder wh-full jcc-aifs header__hamburger-active`
              : `header__hamburger-holder wh-full jcc-aifs`
          }
        >
          <HeaderContent className='header__hamburger-content jcsb-aic wrap' />
        </div>
      </div>
    </header>
  );
}

function HeaderContent({className}) {
  const {cartState} = useContext(CartContext);
  const {authState} = useContext(AuthContext);

  return (
    <div className={className}>
      <div className='header__titles jcfs-aic wrap'>
        {Object.values(titles.titles).map((title) => (
          <div className='header__titles-title' key={title.title}>
            <Link to={title.link}>{title.title}</Link>
          </div>
        ))}
      </div>
      <div className='header__contribution'>
        <Link to={titles.others.contribution.link}>
          <button>{info.deposit}</button>
        </Link>
      </div>
      <Link className='header__cart jcfs-aic' to={titles.others.cart.link}>
        <div className='header__cart-logo'>
          <Icon icon={cart} style={{color: '#3262aa'}} />
        </div>
        {cartState?.length ? (
          <div className='header__cart-info'>
            <div className='header__cart-count jcc-aic'>
              {cartState?.length <= 99 ? cartState?.length : '99+'}
            </div>
          </div>
        ) : null}
      </Link>
      <Link
        className='header__cabinet jcsb-aic'
        to={
          authState === AuthState.DEFAULT ? titles.others.signup.link : titles.others.cabinet.link
        }
      >
        <div className='header__cabinet-logo'>
          <Icon icon={user} style={{color: '#3262aa'}} />
        </div>
        <div className='header__cabinet-name'>{info.cabinet}</div>
      </Link>
      <div className='header__lang'>
        <p>
          {info.lang}: <span className='header__lang-current'>RU</span> | <span>EN</span>
        </p>
      </div>
      <div className='header__social jcsb-aic'>
        <a
          href={links.tg}
          className='header__social-logo header__social-logo--tg'
          target='_blank'
          rel='noreferrer'
        >
          <Icon icon={tg} style={{color: '#3262aa'}} />
        </a>
        <a
          href={links.vk}
          className='header__social-logo header__social-logo--vk'
          target='_blank'
          rel='noreferrer'
        >
          <Icon icon={vk} style={{color: '#3262aa'}} />
        </a>
      </div>
    </div>
  );
}

export default Header;
