function HiddenForm({requestPayment, paymentFormKeys, formButton}) {
  return (
    <>
      {requestPayment && (
        <form action={requestPayment.redirect_url} method={requestPayment.method || 'GET'} hidden>
          {paymentFormKeys && paymentFormKeys.length > 0 ? (
            paymentFormKeys.map((el) => (
              <input onChange={() => {}} key={el} name={el} value={requestPayment.form[el]} />
            ))
          ) : (
            <input onChange={() => {}} name='default' value='' />
          )}

          <button ref={formButton} type='submit'></button>
        </form>
      )}
    </>
  );
}

export default HiddenForm;
