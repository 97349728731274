function device(state) {
  let currentState = 'desktop';
  let width = window.innerWidth;

  if (width < 1100) {
    currentState = 'mobile';
  }

  return currentState === state ? true : false;
}

export default device;
