import info from '../../info/ru/home.json';
import {HomeLayout} from '../components/layout';
import {ReactComponent as Pin} from '../../svg/pin.svg';
import {MapPopup} from '../components/popup';

import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useState} from 'react';

import device from '../functions/device';
import parseHtml from '../functions/parseHtml';
import {useMediaQuery} from 'react-responsive';

function Home() {
  return (
    <div className='home home__container jcc-aic wrap'>
      <HomeLayout>
        <HomeBanner />
        <Main />
      </HomeLayout>
    </div>
  );
}

function HomeBanner() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});

  return (
    <div className='home__banner relative'>
      <img src='/media/home/banner.png' alt='banner' />
      <div className='home__banner-holder absolute jcc-aic wh-full'>
        <div className='home__banner-container'>
          <div className='home__banner-info'>
            <div style={{display: 'flex'}}>
              {info.logotypes.map((el) => (
                <a href={el.link}>
                  <img
                    style={{
                      width: isMobile ? el.widthMobile : el.width,
                      height: isMobile ? el.heightMobile : el.height
                    }}
                    src={el.path}
                  />
                </a>
              ))}
            </div>
            <h1 className='home__banner-info-title'>{info.banner.title}</h1>
            <div className='home__banner-info-text'>{info.banner.text}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Partners({partnersList}) {
  return Object.values(partnersList).map((partner, index) => {
    return partner.link ? (
      <a
        className='home__partners-link'
        href={partner.link}
        target='_blank'
        rel='noreferrer'
        key={index}
      >
        <img src={partner.image} alt='partner' />
      </a>
    ) : (
      <div className='home__partners-link' key={index}>
        <img src={partner.image} alt='partner' />
      </div>
    );
  });
}

function PartnersLayout({partnersList}) {
  return (
    <div className='home__partners-links'>
      <Partners partnersList={partnersList} />
    </div>
  );
}

function Main() {
  return (
    <div className='home__main'>
      <div className='advert advert__large advert__margin wrap jcsb-aic'>
        <div className='advert__photo'>
          <img src='/media/home/card.jpeg' alt='card' />
        </div>
        <div className='advert__text column jcsb-aic'>
          <div className='advert__block'>
            <h3>{info.card.mission.title}</h3>
            <p>{info.card.mission.text}</p>
          </div>
          <div className='advert__block'>
            <h3>{info.card.goal.title}</h3>
            {parseHtml(info.card.goal.text) || <p>{info.card.goal.text}</p>}
          </div>
        </div>
      </div>

      <h2 className='home__top-slider-text'>{info.top_slider_text}</h2>
      <Slider slides={info.top_slider} />

      <div className='advert__after'>
        <p>{info.card.after}</p>
        <button
          onClick={() =>
            (window.location.href =
              'https://heroleague.ru/uploads/aa99ff76ad421f51ac95814d1d4bd22b2e6ffae4.pdf')
          }
        >
          {info.card.button}
        </button>
      </div>

      <Map />

      <div className='home__partners'>
        {info.partners?.rf?.partners_list ? (
          <div className='home__partners-wrapper'>
            <h2>{info.partners.rf.text}</h2>
            <PartnersLayout partnersList={info.partners.rf.partners_list} />
          </div>
        ) : null}

        {info.partners?.world?.partners_list ? (
          <div className='home__partners-wrapper'>
            <h2>{info.partners.world.text}</h2>
            <PartnersLayout partnersList={info.partners.world.partners_list} />
          </div>
        ) : null}
      </div>
      <div className='advert home__race jcsb-aic'>
        <div className='advert__text'>
          <h3>{info.hero_race.title}</h3>
          <h5>{info.hero_race.more}</h5>
        </div>
        <div className='advert__photo'>
          <img src='/media/home/hero_race.png' alt='hero_race' />
        </div>
      </div>
      {/* <div className='home__store'>
        <h2>{info.store.title}</h2>
        <img src='/media/home/store.png' alt='store' />
        <Link to={titles.titles.store.link}>
          <button>{info.store.goto}</button>
        </Link>
      </div> */}

      <Slider slides={info.bottom_slider} />
    </div>
  );
}

function Map() {
  const [popupCities, setPopupCities] = useState(false);

  return (
    <div className='home__map'>
      <h2>{info.about_us.title}</h2>

      <h5>{info.about_us.text}</h5>

      <div className='home__map-holder relative'>
        <div className='home__map-zones wh-full jcfs-aifs absolute'>
          {popupCities.length > 0 && <MapPopup setter={setPopupCities} info={popupCities} />}

          {info.map.zones.map((zone, zoneIndex) => {
            let zoneClasses = `home__map-zone h-full jcc-aifs relative home__map-zone--${zone.plus}`;

            return (
              <div
                className={zoneClasses}
                key={zoneIndex}
                onClick={() => setPopupCities(zone.cities)}
              >
                <div className='home__map-zone--time'>
                  <h4>GMT +{zone.plus}</h4>
                </div>
                <div className='home__map-zone--gradient absolute flex wh-full'></div>
                <div className='home__map-zone--pins absolute wh-full'>
                  <div className='home__map-zone--block relative wh-full'>
                    {zone.cities.map((city, cityIndex) => {
                      let cityClasses = `home__map-zone--pins jcc-aic absolute home__map-zone--pins-${
                        cityIndex + 1
                      }`;

                      return (
                        <Pin
                          className={cityClasses}
                          src='/media/home/pin.svg'
                          alt='pin'
                          key={city}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <img src='/media/home/map.png' alt='map' />
      </div>
    </div>
  );
}

function Slider({slides}) {
  const config = {
    className: 'home__slider',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />
  };
  const mob_config = {...config, slidesToShow: 2, slidesToScroll: 2};

  let sliderConfig = device('mobile') ? {...mob_config} : {...config};

  function Arrow(props) {
    const {className, onClick} = props;

    return (
      <div className={className} onClick={onClick}>
        <img src='/media/home/arrow.png' alt='arrow' />
      </div>
    );
  }

  return (
    <Slick {...sliderConfig}>
      {Object.values(slides).map((slide, index) => {
        return (
          <div className='home__slider-slide' key={index}>
            <img src={slide.link} alt={index} />
          </div>
        );
      })}
    </Slick>
  );
}

export default Home;
