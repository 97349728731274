import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import titles from '../../info/titles.json';
import ENDPOINT from '../../info/endpoints';
import API from '../functions/api';
import Input, {INPUT_TYPE} from './form/input';
import {CATEGORIES} from '../pages/store';

export const PRODUCT_ITEM_POPUP_STATE = {
  HIDDEN: 'hidden',
  SUCCESS: 'success',
  REJECT: 'reject'
};

function Popup({children, setter, handler}) {
  return (
    <div className='popup wh-full jcc-aic'>
      <div className='popup__holder'>
        <div className='form relative'>
          <div className='popup__close' onClick={handler ? handler : () => setter(false)}>
            <img src='/media/icons/form_close.svg' alt='close' />
          </div>

          {children}
        </div>
      </div>
    </div>
  );
}

function PartnersPopup({setter, info}) {
  const [accept, setAccept] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorData, setErrorData] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    await API.POST(ENDPOINT.forms.partner, query)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setAccept(true);
        }
      })
      .catch((error) => {
        setErrorData(error?.response?.data);
      });

    setIsDisabled(false);
  };

  return (
    <Popup setter={setter}>
      {accept ? (
        <div className='form__holder jcsb-aifs wrap'>
          <h2 className='form__heading'>{info?.thanks}</h2>
          <h4 className='form__subheading'>{info?.subthanks}</h4>
          <div className='form__buttons w-full jcfs-aic'>
            <button onClick={() => setter(false)}>{info?.back}</button>
          </div>
        </div>
      ) : (
        <>
          <h2 className='form__heading'>{info?.title}</h2>

          <form className='form__holder jcsb-aifs wrap' onSubmit={handleSubmit}>
            <Input
              text={info?.company}
              error={errorData?.company_name}
              name='company_name'
              type='text'
              required
            />

            <Input
              text={info?.name}
              error={errorData?.agent_name}
              name='agent_name'
              type='text'
              required
            />

            <Input text={info?.number} error={errorData?.phone} name='phone' type='tel' required />

            <Input text={info?.email} error={errorData?.email} name='email' type='email' required />

            <Input
              text={info?.comment}
              error={errorData?.comment}
              name='comment'
              labelClass='w-full'
              inputType={INPUT_TYPE.TEXTAREA}
              required
            />

            <h4 className='w-full'>{info?.looking}</h4>

            <div className='form__buttons w-full jcfs-aic'>
              <button className='inactive' type='submit' disabled={isDisabled}>
                {info?.send}
              </button>
            </div>
          </form>
        </>
      )}
    </Popup>
  );
}

export const ContactsPopupEndpoint = {
  REGIONAL: ENDPOINT.forms.regional,
  PARTICIPANT: ENDPOINT.forms.participant
};

function ContactsPopup({setter, info, initialAcceptState = false, endpoint = null}) {
  const [accept, setAccept] = useState(initialAcceptState);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorData, setErrorData] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    if (endpoint) {
      await API.POST(endpoint, query)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setAccept(true);
          }
        })
        .catch((error) => {
          setErrorData(error?.response?.data);
        });

      setIsDisabled(false);
    }
  };

  return (
    <Popup setter={setter}>
      {accept ? (
        <div className='form__holder jcsb-aifs wrap'>
          <h2 className='form__heading'>{info?.thanks}</h2>
          <h4 className='form__subheading'>{info?.subthanks}</h4>
          <div className='form__buttons w-full jcfs-aic'>
            <button onClick={() => setter(false)}>{info?.back}</button>
          </div>
        </div>
      ) : (
        <>
          <h2 className='form__heading'>{info?.title}</h2>

          <form className='form__holder jcsb-aifs wrap' onSubmit={handleSubmit}>
            <Input text={info?.fio} name='name' error={errorData?.name} type='text' required />

            <Input
              text={info?.region}
              name='region'
              error={errorData?.region}
              type='text'
              required
            />
            <Input text={info?.number} name='phone' error={errorData?.phone} type='tel' required />

            <Input text={info?.email} name='email' error={errorData?.email} type='text' required />

            <Input
              text={info?.comment}
              name='comment'
              error={errorData?.comment}
              inputType={INPUT_TYPE.TEXTAREA}
              labelClass='w-full'
              required
            />

            <h4 className='w-full'>{info?.looking}</h4>

            <div className='form__buttons w-full jcfs-aic'>
              <button className='inactive' type='submit' disabled={isDisabled}>
                {info?.send}
              </button>
            </div>
          </form>
        </>
      )}
    </Popup>
  );
}

function SigninPopup({info, setter, handler}) {
  const [accept, setAccept] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    await API.POST(ENDPOINT.password_reset, query)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setAccept(true);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data);
      });

    setIsDisabled(false);
  };

  return (
    <Popup setter={setter}>
      {accept ? (
        <div className='form__holder jcsb-aifs wrap'>
          <h2 className='form__heading'>{info.confirm}</h2>
          <h4 className='form__subheading'>{info.text}</h4>

          <div className='form__buttons w-full jcfs-aic'>
            <button onClick={handler}>{info.back}</button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h2 className='form__heading'>{info.title}</h2>
          <h4 className='form__subheading'>{info.subtitle}</h4>

          <div className='form__holder jcsb-aifs wrap'>
            <Input text={info?.email} name='email' type='email' required />

            <div className='form__buttons w-full jcfs-aic'>
              <button className='inactive' type='submit' disabled={isDisabled}>
                {info.send}
              </button>
            </div>
          </div>
        </form>
      )}
    </Popup>
  );
}

const SignupPopup = ({setter, info, isOrganizer}) => {
  const navigate = useNavigate();

  return (
    <Popup setter={setter}>
      <h2 className='form__heading'>{info.title}</h2>
      <h4 className='form__subheading'>
        {isOrganizer ? info.organizer_text : info.sportsman_text}
      </h4>

      <div className='form__holder jcsb-aifs wrap'>
        <div className='form__buttons w-full jcfs-aic'>
          <button onClick={() => navigate('/')}>{info.back}</button>
        </div>
      </div>
    </Popup>
  );
};

const PasswordResetPopup = ({handler, info, isSuccess}) => {
  const navigate = useNavigate();

  return (
    <Popup handler={handler}>
      <h2 className='form__heading'>{isSuccess ? info.title_success : info.title_fail}</h2>
      <h4 className='form__subheading'>{isSuccess ? info.text_success : info.text_fail}</h4>

      <div className='form__holder jcsb-aifs wrap'>
        <div className='form__buttons w-full jcfs-aic'>
          <button onClick={() => navigate('/')}>{info.back}</button>
        </div>
      </div>
    </Popup>
  );
};

const CabinetPopup = ({setter = null, handler = null, info}) => (
  <Popup setter={setter} handler={handler}>
    <h2 className='form__heading'>{info.title}</h2>
    <h4 className='form__subheading'>{info.subtitle}</h4>

    <div className='form__holder jcsb-aifs wrap'>
      <div className='form__buttons w-full jcfs-aic'>
        <button onClick={handler ? handler : () => setter(false)}>{info.back}</button>
      </div>
    </div>
  </Popup>
);

function CartPopup({setter, info}) {
  const navigate = useNavigate();

  return (
    <Popup setter={setter}>
      <h2 className='form__heading'>{info.title}</h2>
      <h4 className='form__subheading'>{info.subtitle}</h4>

      <div className='form__holder jcsb-aifs wrap'>
        <div className='form__buttons w-full jcfs-aic'>
          <button onClick={() => navigate('/')}>{info.back}</button>
        </div>
      </div>
    </Popup>
  );
}

function ContributionPopup({setter, info}) {
  const [accept, setAccept] = useState(false);

  return (
    <Popup setter={setter}>
      {accept ? (
        <div className='form__holder jcsb-aifs wrap'>
          <h2 className='form__heading'>{info.thanks}</h2>
          <h4 className='form__subheading'>{info.subthanks}</h4>
          <div className='form__buttons w-full jcfs-aic'>
            <button onClick={() => setter(false)}>{info.back}</button>
          </div>
        </div>
      ) : (
        <>
          <h2 className='form__heading'>{info.title}</h2>

          <div className='form__holder jcsb-aifs wrap contribution__form'>
            <label>
              {info.fio}
              <input type='text' />
            </label>
            <label>
              {info.city}
              <input type='text' />
            </label>
            <label>
              {info.number}
              <input type='text' />
            </label>
            <label>
              {info.email}
              <input type='text' />
            </label>

            <div className='form__buttons w-full jcfs-aic wrap contribution__popup-upper'>
              <h4>{info.photo}</h4>

              <div className='jcsb-aic'>
                <button onClick={() => setAccept(true)}>{info.add}</button>

                <h6 className='inline'>{info.unchosen}</h6>
              </div>
            </div>

            <div className='form__buttons w-full jcfs-aifs column wrap contribution__popup-lower'>
              <div className='contribution__popup-lower--price'>
                <h4 className='inline'>{info.price}:</h4>
                <h3 className='inline'>{info.value} руб.</h3>
              </div>

              <button onClick={() => setAccept(true)}>{info.buy}</button>
            </div>
          </div>
        </>
      )}
    </Popup>
  );
}

const PRODUCT_SIZE_CATEGORY = {
  MALE_DEFAULT: 'male_default',
  MALE_SUIT: 'male_suit',
  FEMALE_DEFAULT: 'female_default',
  FEMALE_SUIT: 'female_suit',
  UNISEX: 'unisex'
};

function ProductPopup({setter, info, category}) {
  const [type, setType] = useState(PRODUCT_SIZE_CATEGORY.MALE_DEFAULT);

  useEffect(() => {
    if (category !== CATEGORIES.MALE && category !== CATEGORIES.FEMALE) {
      setType(PRODUCT_SIZE_CATEGORY.UNISEX);

      return;
    }

    if (category === CATEGORIES.MALE) {
      setType(PRODUCT_SIZE_CATEGORY.MALE_DEFAULT);

      return;
    }

    if (category === CATEGORIES.FEMALE) {
      setType(PRODUCT_SIZE_CATEGORY.FEMALE_DEFAULT);

      return;
    }
  }, [category]);

  return (
    <Popup setter={setter}>
      <div className='form__holder jcsb-aifs wrap product__sizetable'>
        <h2 className='form__heading'>{info.title}</h2>

        <div className='form__buttons w-full jcfs-aic wrap'>
          {category === CATEGORIES.MALE ? (
            <>
              <button
                style={{
                  background: `${
                    type === PRODUCT_SIZE_CATEGORY.MALE_DEFAULT ? '#1050c7' : '#315fb6'
                  }`
                }}
                className='form__buttons-button--size'
                onClick={() => setType(PRODUCT_SIZE_CATEGORY.MALE_DEFAULT)}
              >
                {info.male_default.title}
              </button>
              <button
                style={{
                  background: `${type === PRODUCT_SIZE_CATEGORY.MALE_SUIT ? '#1050c7' : '#315fb6'}`
                }}
                className='form__buttons-button--size'
                onClick={() => setType(PRODUCT_SIZE_CATEGORY.MALE_SUIT)}
              >
                {info.male_suit.title}
              </button>
            </>
          ) : null}

          {category === CATEGORIES.FEMALE ? (
            <>
              <button
                style={{
                  background: `${
                    type === PRODUCT_SIZE_CATEGORY.FEMALE_DEFAULT ? '#1050c7' : '#315fb6'
                  }`
                }}
                className='form__buttons-button--size'
                onClick={() => setType(PRODUCT_SIZE_CATEGORY.FEMALE_DEFAULT)}
              >
                {info.female_default.title}
              </button>
              <button
                style={{
                  background: `${
                    type === PRODUCT_SIZE_CATEGORY.FEMALE_SUIT ? '#1050c7' : '#315fb6'
                  }`
                }}
                className='form__buttons-button--size'
                onClick={() => setType(PRODUCT_SIZE_CATEGORY.FEMALE_SUIT)}
              >
                {info.female_suit.title}
              </button>
            </>
          ) : null}
        </div>

        <ProductTable data={info[type].table} />
      </div>
    </Popup>
  );
}

const ProductTable = ({data}) => (
  <table className='table'>
    <thead>
      <tr>
        {data.titles?.map((title) => (
          <th key={title}>{title}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.values?.map((item, index) => (
        <tr key={index}>
          {Object.entries(item).map((entry, index) => {
            let key = entry[0];
            let value = entry[1];
            let className = `table__${key}`;

            return (
              <td key={index} className={className}>
                {value}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

function ProductAddPopup({handler, info, popupState}) {
  const navigate = useNavigate();

  return (
    <Popup handler={handler}>
      <h2 className='form__heading'>
        {popupState === PRODUCT_ITEM_POPUP_STATE.SUCCESS ? info.title.success : info.title.reject}
      </h2>
      <h4 className='form__subheading'>
        {popupState === PRODUCT_ITEM_POPUP_STATE.SUCCESS
          ? info.subtitle.success
          : info.subtitle.reject}
      </h4>

      <div className='form__holder jcsb-aifs wrap'>
        <div className='form__buttons w-full jcfs-aic'>
          <button onClick={() => navigate(titles.others.cart.link)}>{info.to_cart}</button>
          <button onClick={handler}>{info.continue}</button>
        </div>
      </div>
    </Popup>
  );
}

function MapPopup({setter, info}) {
  return (
    <Popup setter={setter}>
      <div className='form__holder home__map-popup jcsb-aifs wrap'>
        {info.map((city, index) => {
          return <h4 key={index}>{city}</h4>;
        })}
      </div>
    </Popup>
  );
}

export default Popup;

export {
  PartnersPopup,
  ContactsPopup,
  SigninPopup,
  SignupPopup,
  PasswordResetPopup,
  CabinetPopup,
  CartPopup,
  ContributionPopup,
  ProductPopup,
  ProductAddPopup,
  MapPopup
};
