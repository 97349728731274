import info from '../../../info/ru/cabinet.json';

export default function Activity() {
  return (
    <>
      <h2 className='cabinet__heading'>{info.activity.heading}</h2>

      <h5>{info.activity.subheading}</h5>

      {info.activity.sessions.map((session, index) => {
        return (
          <div className='cabinet__activity jcfs-aifs' key={index}>
            <div className='cabinet__activity-location jcfs-aic'>
              <div className='cabinet__activity-map'>
                <img src='/media/icons/map.svg' alt='map' />
              </div>
              <div className='cabinet__activity-data'>
                <h3>{session.city}</h3>
                <h5 className='jcfs-aic'>
                  <span className={session.active ? 'cabinet__activity-data--active' : ''}>
                    {session.active ? info.activity.active : session.last}
                  </span>
                  <span>{session.pc}</span>
                  <span>{session.browser}</span>
                </h5>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
