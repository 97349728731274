import {BannerLayout} from '../components/layout';
import Cards from '../components/cards';
import API from '../functions/api';
import {useState, useEffect} from 'react';
import ENDPOINT from '../../info/endpoints';

function Federation() {
  const [data, setData] = useState({});

  useEffect(() => {
    API.GET(ENDPOINT.federation).then((response) => setData(response.data));
  }, []);

  return (
    <div className='federation federation__container jcc-aic wrap'>
      <BannerLayout background={data?.banner} title={data?.title}>
        <Main data={data} />
      </BannerLayout>
    </div>
  );
}

function Main({data}) {
  return (
    <div className='federation__main'>
      <Cards json={data?.cards} />
    </div>
  );
}

export default Federation;
