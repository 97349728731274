import React, {useState} from 'react';
import Cookies from 'js-cookie';

const cookiesName = 'authState';

const AuthContext = React.createContext({});

export const AuthProvider = ({children}) => {
  const [authState, updateAuthState] = useState(Cookies.get(cookiesName));

  const setAuthState = async (state) =>
    new Promise((resolve) => {
      if (state !== Cookies.get(cookiesName)) {
        Cookies.set(cookiesName, state, {
          expires: new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        });

        updateAuthState(state);

        resolve(true);
      }
    });

  return <AuthContext.Provider value={{authState, setAuthState}}>{children}</AuthContext.Provider>;
};

export const AuthState = {
  SPORTSMAN: 'sportsman',
  ORGANIZER: 'organizer',
  DEFAULT: null
};

export default AuthContext;
