import React, {useMemo, useState} from 'react';

const CartContext = React.createContext({});

export const CART_STORAGE_NAME = 'cartItems';

const countIsEnough = (items, item) => {
  const limitCount = item.size?.count;
  const currentCount = items.filter(
    (currentItem) => currentItem.size?.public_id === item.size?.public_id
  ).length;

  return currentCount + 1 <= limitCount;
};

export const CartProvider = ({children}) => {
  const [cartState, setCartState] = useState(
    JSON.parse(localStorage.getItem(CART_STORAGE_NAME)) || []
  );

  const updateCartState = useMemo(
    () => ({
      addItem: (newItem) => {
        let isAvailable = false;

        setCartState((items) => {
          isAvailable = newItem?.available && countIsEnough(items, newItem);

          const resultItems = isAvailable ? [...items, newItem] : items;

          localStorage.setItem(CART_STORAGE_NAME, JSON.stringify(resultItems));

          return resultItems;
        });

        return isAvailable;
      },

      removeItem: (oldItem) => {
        setCartState((items) => {
          const resultItems = [
            ...items.filter((item) => item.size.public_id !== oldItem.size.public_id),
            ...items.filter((item) => item.size.public_id === oldItem.size.public_id).slice(0, -1)
          ];

          localStorage.setItem(CART_STORAGE_NAME, JSON.stringify(resultItems));

          return resultItems;
        });
      },

      clearItem: (oldItem) => {
        setCartState((items) => {
          const resultItems = items.filter(
            (item) => item.size.public_id !== oldItem.size.public_id
          );

          localStorage.setItem(CART_STORAGE_NAME, JSON.stringify(resultItems));

          return resultItems;
        });
      },

      clear: () => {
        setCartState(() => {
          localStorage.removeItem(CART_STORAGE_NAME);

          return [];
        });
      }
    }),
    []
  );

  return (
    <CartContext.Provider value={{cartState, updateCartState}}>{children}</CartContext.Provider>
  );
};

export default CartContext;
