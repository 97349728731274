import {BannerLayout} from '../components/layout';
import {useState, useEffect} from 'react';
import API from '../functions/api';
import ENDPOINT from '../../info/endpoints';
import info from '../../info/ru/cabinet.json';
import {useParams} from 'react-router-dom';

function Calendar() {
  const {year} = useParams();
  const [data, setData] = useState({});
  const [currentYear, setCurrentYear] = useState(Number(year) || 2024);

  useEffect(() => {
    if (year && !data?.events?.length) {
      API.GET(ENDPOINT.calendar + `${year}`).then((response) => setData(response.data));
    } else {
      API.GET(ENDPOINT.calendar + `${currentYear}`).then((response) => setData(response.data));
    }
  }, [currentYear]);

  return (
    <div className='calendar calendar__container jcc-aic wrap'>
      <BannerLayout background={data?.banner} title={data?.title}>
        <Main data={data} setCurrentYear={setCurrentYear} currentYear={currentYear} />
      </BannerLayout>
    </div>
  );
}

const Main = ({data, setCurrentYear, currentYear}) => {
  return (
    <div className='calendar__main'>
      <div className='calendar__year'>
        <span
          onClick={() => setCurrentYear(2024)}
          className={
            currentYear === 2024 ? 'calendar__items' : 'calendar__items calendar__items_active'
          }
        >
          2024
        </span>
        <span
          onClick={() => setCurrentYear(2023)}
          className={
            currentYear === 2023 ? 'calendar__items' : 'calendar__items calendar__items_active'
          }
        >
          2023
        </span>
      </div>
      {[...(data.events || [])].reverse().map((event, index) => (
        <div key={index}>
          <h2 className='calendar__heading'>{event?.month}</h2>

          <div className='calendar__events jcfs-aic'>
            {event?.events?.map((event, index) => (
              <Event event={event} key={index} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const Event = ({event}) => (
  <div className='advert calendar__event column jcsb-aic'>
    <div className='calendar__event-header'>
      <div className='calendar__event-header--top jcsb-aic'>
        <h2 className='inline'>{event?.date}</h2>
        <div className='calendar__event-header--logo'>
          <img src={event?.logo} alt='event' />
        </div>
      </div>
      <div className='calendar__event-header--text'>
        <h5>{event?.description}</h5>
      </div>
    </div>

    <div className='calendar__event-footer'>
      <h2>{event?.title}</h2>
      <h5>{event?.subtitle}</h5>

      <div className='calendar__event-footer-action'>
        <button>
          <a href={event?.link} target='_blank' rel='noopener noreferrer'>
            {event?.button}
          </a>
        </button>
        {event?.protocol ? (
          <p className='cards__card-button calendar__event-protocol'>
            <a href={event?.protocol} target='_blank' rel='noopener noreferrer'>
              {info.qual.form.file}
            </a>
          </p>
        ) : null}
      </div>
    </div>
  </div>
);

export default Calendar;
