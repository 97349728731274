export const INPUT_TYPE = {
  DEFAULT: 'input',
  TEXTAREA: 'textarea',
  SELECT: 'select'
};

function InputWrapper({fixedSymbol, children}) {
  return fixedSymbol ? <div className='fixed-symbol__wrapper'>{children}</div> : children;
}

function Input({
  text,
  textAfterInput,
  labelClass,
  error,
  inputType = INPUT_TYPE.DEFAULT,
  fixedSymbol,
  ...rest
}) {
  return (
    <label className={labelClass || ''}>
      {text ? text : null}

      <InputWrapper fixedSymbol={fixedSymbol}>
        {fixedSymbol ? <span className='fixed-symbol'>{fixedSymbol}</span> : null}

        {inputType === INPUT_TYPE.DEFAULT && <input {...rest} />}
        {inputType === INPUT_TYPE.TEXTAREA && <textarea {...rest} />}
        {inputType === INPUT_TYPE.SELECT && (
          <div className='select'>
            <select {...rest}>{rest?.children}</select>
          </div>
        )}
      </InputWrapper>

      {textAfterInput}

      {error?.map((value, index) => (
        <p className='error' key={index}>
          {value}
        </p>
      ))}
    </label>
  );
}

export default Input;
