import {useState} from 'react';
import info from '../../../info/ru/cabinet.json';
import Cards from '../../components/cards';
import Input, {INPUT_TYPE} from '../../components/form/input';
import {CabinetPopup} from '../../components/popup';
import API from '../../functions/api';
import {pageReload} from '../../functions/pageReload';

export default function Qual({data}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [selectedEventURL, setSelectedEventURL] = useState(
    data?.events?.archive_events[0]?.protocol_upload_url
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    await API.POST(selectedEventURL, query, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setErrorData({});
          setShowPopup(true);
        }
      })
      .catch((error) => {
        setErrorData(error?.response?.data);
      });

    setIsDisabled(false);
  };

  const handleSectionChange = (e) => setSelectedEventURL(e.target.value);

  return (
    <>
      {showPopup && <CabinetPopup handler={pageReload} info={info.qual.popup} />}

      <h2 className='cabinet__heading'>{info.qual.heading}</h2>

      <form className='cabinet__qual form' onSubmit={handleSubmit}>
        <div className='login__form jcsb-aifs wrap'>
          <Input
            text={info.qual.form.file + '*'}
            type='file'
            error={errorData?.file}
            name='file'
            required
          />

          <Input
            text={info.qual.form.type + '*'}
            inputType={INPUT_TYPE.SELECT}
            error={errorData?.type}
            name='type'
            required
          >
            <option value='pdf'>PDF</option>
            <option value='word'>Word</option>
            <option value='excel'>Excel</option>
          </Input>

          <Input
            text={info.qual.form.title + '*'}
            type='text'
            error={errorData?.title}
            name='title'
            required
          />

          {data?.document_sections && data?.events?.archive_events ? (
            <>
              <Input
                text={info.qual.form.event + '*'}
                inputType={INPUT_TYPE.SELECT}
                name='event'
                required
                onChange={handleSectionChange}
              >
                {data?.events?.archive_events?.map((event, index) => (
                  <option value={event?.protocol_upload_url} key={index}>
                    {event?.title} ({event?.date})
                  </option>
                ))}
              </Input>

              <Input
                text={info.qual.form.section + '*'}
                inputType={INPUT_TYPE.SELECT}
                error={errorData?.section}
                name='section'
                required
              >
                {data?.document_sections?.map((section, index) => (
                  <option value={section?.id} key={index}>
                    {section?.name}
                  </option>
                ))}
              </Input>

              <h4 className='w-full'>{info.qual.form.looking}</h4>

              <div className='form__buttons w-full jcfs-aic'>
                <button type='submit' className='inactive' disabled={isDisabled}>
                  {info.qual.form.send}
                </button>

                <Input
                  textAfterInput={
                    <>
                      {info.qual.form.agree.title}&nbsp;<span>{info.qual.form.agree.personal}</span>
                    </>
                  }
                  labelClass={'label_checkbox jcc-aic'}
                  type='checkbox'
                  name='agreement'
                  required
                />
              </div>
            </>
          ) : (
            <p className='error'>{info.qual.form.section_404}</p>
          )}
        </div>
      </form>

      <Protocols protocols={data?.protocols} />
    </>
  );
}

const Protocols = ({protocols}) => (
  <div className='cabinet__qual-protocols'>
    <Cards
      json={{
        button: info.qual.protocol_button,
        cards: protocols?.map((protocol) => ({
          ...protocol,
          link: protocol.file
        }))
      }}
    />
  </div>
);
