import {BannerLayout} from '../components/layout';
import info from '../../info/ru/contacts.json';
import {ContactsPopup, ContactsPopupEndpoint} from '../components/popup';
import {useEffect, useState} from 'react';
import API from '../functions/api';
import ENDPOINT, {MANAGER_ORIGIN} from '../../info/endpoints';
import Input, {INPUT_TYPE} from '../components/form/input';
import {externalAPI} from './store';

function Contacts() {
  return (
    <div className='contacts contacts__container jcc-aic wrap'>
      <BannerLayout background={info.banner} title={info.title}>
        <Main />
      </BannerLayout>
    </div>
  );
}

function Main() {
  const [showPopup, setShowPopup] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorData, setErrorData] = useState({});

  const [management, setData] = useState([]);

  useEffect(() => {
    API.GET(ENDPOINT.management, externalAPI).then((response) => {
      if (response?.data?.values) {
        setData(response.data.values);
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const form = new FormData(e.target);
    const query = Object.fromEntries(form.entries());

    await API.POST(ENDPOINT.forms.feedback, query)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setShowFeedbackPopup(true);
        }
      })
      .catch((error) => {
        setErrorData(error?.response?.data);
      });

    setIsDisabled(false);
  };

  return (
    <div className='contacts__main jcsb-aifs'>
      {showPopup && (
        <ContactsPopup
          setter={setShowPopup}
          info={info.popup}
          endpoint={ContactsPopupEndpoint.PARTICIPANT}
        />
      )}
      {showFeedbackPopup && (
        <ContactsPopup setter={setShowFeedbackPopup} info={info.popup} initialAcceptState={true} />
      )}

      <div className='contacts__left'>
        {management?.length > 0 &&
          Object.values(management).map((human) => (
            <div className='advert contacts__people jcsb-aic' key={human.fio}>
              <div className='advert__photo'>
                <img src={`${MANAGER_ORIGIN}${human.picture}`} alt={human.fio} />
              </div>
              <div className='advert__text'>
                <h4>{human.position}</h4>
                <h2>{human.fio}</h2>
              </div>
            </div>
          ))}
        <div className='advert contacts__card jcsb-aic'>
          <div className='advert__text'>
            <h3>{info.card.text}</h3>
            <button onClick={() => setShowPopup(true)}>{info.card.button}</button>
          </div>
          <div className='advert__photo'>
            <img src='/media/contribution/banner.png' alt='card' />
          </div>
        </div>
      </div>
      <div className='contacts__right flex'>
        <div className='advert flex'>
          <form className='advert__text column jcsb-aifs' onSubmit={handleSubmit}>
            <h2>{info.contact_us.title}</h2>

            <h4>{info.contact_us.text}</h4>

            <Input
              text={info.contact_us.fields.fio.title}
              placeholder={info.contact_us.fields.fio.placeholder}
              type='name'
              name='name'
              error={errorData?.name}
              required
            />

            <Input
              text={info.contact_us.fields.email.title}
              placeholder={info.contact_us.fields.email.placeholder}
              type='email'
              name='email'
              error={errorData?.email}
              required
            />

            <Input
              text={info.contact_us.fields.comment.title}
              placeholder={info.contact_us.fields.comment.placeholder}
              inputType={INPUT_TYPE.TEXTAREA}
              name='comment'
              error={errorData?.comment}
              required
            />

            <button type='submit' className='inactive' disabled={isDisabled}>
              {info.contact_us.button}
            </button>

            <h5>{info.contact_us.agree}</h5>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
